import axios from 'axios';

let isLoggingOut = false; // To prevent multiple alerts and redirection

// Create an instance of axios
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Add an interceptor to check for 403 errors (token expiration)
export const setupInterceptors = (navigate) => {
  api.interceptors.response.use(
    (response) => {
      // Return the response if everything is fine
      return response;
    },
    (error) => {
      // Check if it's a 403 error (forbidden)
      if (error.response && error.response.status === 403) {
        // Token expired or user unauthorized

        if (!isLoggingOut) {
          isLoggingOut = true; // Prevent multiple alerts and redirection

          alert('Session expired. Please log in again.');

          // Perform logout: Clear token and redirect to login page
          localStorage.removeItem('token'); // Assuming you store the token in localStorage
          localStorage.removeItem('user'); // Assuming you store the user in localStorage

          navigate('/login'); // Redirect to login page

          // Optional: Refresh the page or force re-render
          window.location.reload(); // Ensure the app doesn't retry requests
        }
      }
      // Reject the error to allow other handlers to catch it as well
      return Promise.reject(error);
    }
  );
};

export default api;
