import React, { useEffect, useState } from 'react';
import SearchField from './SearchField';
import SupplierList from './SupplierList';

const Suppliers = ({ supplierList }) => {
  const [searchValue, setSearchValue] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [supplierSelected, setSupplierSelected] = useState('');

  // UseEffect to set the list of suppliers to filteredData
  useEffect(() => {
    setFilteredData(supplierList);
    setSearchResult(supplierList);
  }, [supplierList]);

  // Function to filter cases based on search input
  useEffect(() => {
    const lowerCaseValue = searchValue.toLowerCase();

    //Create an array with filtered data
    const filtered =
      filteredData &&
      filteredData.filter((item) => {
        const matchesName =
          item?.name && item?.name.toLowerCase().includes(lowerCaseValue);
        const matchesCountry =
          item?.countryOfOrigin &&
          item?.countryOfOrigin.toLowerCase().includes(lowerCaseValue);
        const matchesManufacturer =
          item?.manufacturer &&
          item?.manufacturer.toLowerCase().includes(lowerCaseValue);
        return matchesName || matchesCountry || matchesManufacturer;
      });
    setSearchResult(filtered);
  }, [searchValue, filteredData]);

  // Function to handle closing search bar
  const handleCloseSearch = () => {
    setSearchValue('');
  };

  //Code to handle supplier change
  const handleSupplierSelection = (item) => {
    setSupplierSelected(item);
    // setSideBar(!sideBar);

    // navigate(`/pharmacy/inventory/${inventorySelected}/${item.name}`);
  };

  return (
    <div
      className='flex-[3] flex flex-col gap-3'
      style={{ height: 'calc(100vh - 140px)' }}
    >
      <div className='bg-white p-2 rounded-lg shadow-lg'>
        <SearchField
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          handleCloseSearch={handleCloseSearch}
          placeholder='Scan or Search Supplier ...'
        />
      </div>
      <SupplierList
        searchResult={searchResult}
        handleSupplierSelection={handleSupplierSelection}
      />
    </div>
  );
};

export default Suppliers;
