import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { IoArrowUp } from 'react-icons/io5';
import Button from './Button';

const OrdersAnalytics = ({ handlePageSelection, ordersList, supplierList }) => {
  return (
    <div className='flex flex-col gap-3 overflow-y-auto'>
      <div className='flex items-center justify-start gap-5'>
        <Button
          label='Generate P.O'
          className='flex items-center justify-center gap-2 text-white text-[16px] bg-accent hover:bg-primary w-fit p-2 rounded-lg shadow-lg cursor-pointer'
          onClick={() => handlePageSelection('generate_P.O')}
        />

        <Button
          label='Add Supplier'
          className='flex items-center justify-center gap-2 text-white text-[16px] bg-accent hover:bg-primary w-fit p-2 rounded-lg shadow-lg cursor-pointer'
          onClick={() => handlePageSelection('add_supplier')}
        />
      </div>
      <div className='flex flex-wrap gap-5'>
        <div className='p-5 bg-white rounded-lg shadow-lg w-[250px]'>
          <Link
            to='/pharmacy/orders/orders'
            className='flex-1 text-center cursor-pointer'
          >
            <div onClick={() => handlePageSelection('orders')}>
              <h6 className='text-xl font-light font-urbanist truncate text-left'>
                Orders
              </h6>
              <div className='flex items-center justify-between gap-5'>
                <div className='flex-[4] flex items-end  gap-2 mt-3'>
                  <h5 className='text-3xl'>
                    {ordersList && ordersList.length}
                  </h5>
                  <p className='text-sm pb-[2px]'>Orders</p>
                </div>
                <div className='flex-1 flex gap-1'>
                  <div className='flex items-center justify-center'>
                    <p>20%</p>
                    <p
                      className='text-xl text-green-500'

                      // className={`text-xl ${
                      //   isPositive ? 'text-green-500' : 'text-red-500'
                      // }`}
                    >
                      <IoArrowUp />
                      {/* {isPositive ? <IoArrowUp /> : <IoArrowDown />} */}
                    </p>
                  </div>
                  {/* <p className='text-xs text-gray-500'>Compared to last month</p> */}
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className='p-5 bg-white rounded-lg shadow-lg w-[250px]'>
          <Link
            to='/pharmacy/orders/suppliers'
            className='flex-1 text-center cursor-pointer'
          >
            <div onClick={() => handlePageSelection('suppliers')}>
              <h6 className='text-xl font-light font-urbanist truncate text-left'>
                Suppliers
              </h6>
              <div className='flex items-center justify-between gap-5'>
                <div className='flex-[4] flex items-end  gap-2 mt-3'>
                  <h5 className='text-3xl'>
                    {supplierList && supplierList.length}
                  </h5>
                  <p className='text-sm pb-[2px]'>Suppliers</p>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OrdersAnalytics;
