import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BiSolidHide } from 'react-icons/bi';
import { BiShow } from 'react-icons/bi';
import Button from '../../components/Button';
import InputField from '../../components/InputField';
import axios from 'axios';
import { AuthContext } from '../../context/AuthProvider';
import { amg_logo } from '../../assets';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import colors from '../../assets/colors';

const Login = () => {
  const { login } = useContext(AuthContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const navigate = useNavigate();

  //Function to handle user login
  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    // Show a "Saving..." toast notification while data is being processed
    const savingToastId = toast.info(`Logging In User ...`, {
      position: 'top-center',
      autoClose: false, // Keep it open until dismissed manually
      style: {
        backgroundColor: '#FFD700', // Customize the background color (yellowish for "saving")
        color: '#000',
        textAlign: 'center',
      },
      icon: false,
      hideProgressBar: true,
    });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        {
          username,
          password,
        }
      );

      // Dismiss the "Saving..." toast
      toast.dismiss(savingToastId);

      if (response.data.success) {
        login(response.data.accessToken, response.data.user);
        localStorage.setItem('token', response.data.accessToken);
        toast.success(`User logged in successfully!`, {
          position: 'top-center',
          autoClose: 500,
          // Styling the pop-up message
          style: {
            backgroundColor: `${colors.success}`,
            color: '#fff',
            textAlign: 'center',
          },
          icon: false,
          // Remove the progress bar
          hideProgressBar: true,
        });
        navigate('/pharmacy/dashboard');
      } else {
        setError(response.data.message);
        toast.error(`Failed to log in user: ${response.data.message} `, {
          position: 'top-center',
          icon: false,
          // Remove the progress bar
          hideProgressBar: true,
          // Styling the pop-up message
          style: {
            backgroundColor: `${colors.error}`,
            color: '#fff',
            textAlign: 'center',
          },
        });
      }
    } catch (error) {
      // Dismiss the "Saving..." toast
      toast.dismiss(savingToastId);
      if (error.response) {
        if (error.response.status === 401) {
          setError('Invalid username or password');
          toast.error(`Invalid username or password`, {
            position: 'top-center',
            icon: false,
            // Remove the progress bar
            hideProgressBar: true,
            // Styling the pop-up message
            style: {
              backgroundColor: `${colors.error}`,
              color: '#fff',
              textAlign: 'center',
            },
          });
        } else {
          setError('An error occurred. Please try again.');
          toast.error(`An error occurred. Please try again. `, {
            position: 'top-center',
            icon: false,
            // Remove the progress bar
            hideProgressBar: true,
            // Styling the pop-up message
            style: {
              backgroundColor: `${colors.error}`,
              color: '#fff',
              textAlign: 'center',
            },
          });
        }
      } else {
        setError('An error occurred. Please try again.');
        toast.error(`An error occurred. Please try again. `, {
          position: 'top-center',
          icon: false,
          // Remove the progress bar
          hideProgressBar: true,
          // Styling the pop-up message
          style: {
            backgroundColor: `${colors.error}`,
            color: '#fff',
            textAlign: 'center',
          },
        });
      }
    }
  };

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible((prevVisible) => !prevVisible);
  };

  return (
    <div className='flex items-center justify-center w-screen m-auto h-screen bg-[#F4F6F9]'>
      <div className='flex flex-col lg:flex-row md:gap-4 items-center justify-center rounded-lg w-[350px] md:w-[50%] bg-white p-5 md:p-10  shadow-xl'>
        <div className='flex-1 flex items-center justify-center gap-4'>
          <img
            src={amg_logo}
            alt='amazing grace logo'
            className='w-32 h-32 object-contain'
          />
          <div>
            <p className='flex flex-col capitalize text-lg font-light'>
              <span className='text-3xl'>Amazing Grace</span>
              <span>health care</span> <span>and Diagnostics</span>
            </p>
          </div>
        </div>
        <div className='flex-1 w-full'>
          <div className='flex flex-col items-center justify-center'>
            <h2 className='max-sm:py-5 md:mb-5 font-light text-center text-[24px] w-full'>
              User Login
            </h2>
          </div>
          <form onSubmit={handleLogin} className='flex flex-col gap-5 w-full'>
            <InputField
              label='Username'
              type='text'
              name='username'
              value={username}
              onChange={(e) => setUsername(e.target.value.trim())}
            />
            <div className='flex relative'>
              <div
                onClick={togglePasswordVisibility}
                className='absolute flex top-5 right-5 cursor-pointer z-10'
              >
                <div className={`${passwordVisible ? 'block' : 'hidden'}`}>
                  <BiSolidHide className='text-xl' />
                </div>
                <div className={`${passwordVisible ? 'hidden' : 'block'}`}>
                  <BiShow className='text-xl' />
                </div>
              </div>
              <InputField
                label='Password'
                type={passwordVisible ? 'text' : 'password'}
                name='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {error && <p className='text-red-500 text-sm'>{error}</p>}
            <div className='flex gap-4 items-center justify-between w-full pb-'>
              <a className='text-[12px] underline' href=''>
                Forgot your password?
              </a>
            </div>
            <Button
              label='Login'
              className='px-3 py-2 w-full mt-2 text-lg bg-primary hover:bg-accent text-secondary rounded-lg'
            />
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
