import React, { useContext } from 'react';
import Dashboard from '../pages/pharmacy/Dashboard';
import POS from '../pages/pharmacy/POS';
import Inventory from '../pages/pharmacy/Inventory';
import Orders from '../pages/pharmacy/Orders';
import PurchaseOrders from './PurchaseOrders';
import Reports from '../pages/pharmacy/Reports';
import { InventoryContext } from '../context/InventoryProvider';
import { IoChevronBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { OrderContext } from '../context/OrderProvider';
import Users from '../pages/pharmacy/Users';

const Content = () => {
  const {
    selectedScreen,
    inventorySelected,
    itemDetails,
    setItemDetails,
    addItem,
  } = useContext(InventoryContext);
  const { pageSelected } = useContext(OrderContext);

  const navigate = useNavigate();

  const getOrderTitle = () => {
    if (selectedScreen !== 'orders') return null; // Return null if not in 'orders' screen

    const titles = {
      'generate_P.O': 'Generate Purchase Order',
      add_supplier: 'Add Supplier',
      orders: 'Orders',
      suppliers: 'Suppliers',
    };

    return titles[pageSelected] || ''; // Return the title or an empty string if not found
  };

  return (
    <div className='flex flex-col gap-3 lg:pl-2'>
      <div className='capitalize sticky top-[85px] right-0 text-[28px] mt-1 bg-background font-urbanist font-light text-primary'>
        {!itemDetails ? (
          getOrderTitle() ||
          (selectedScreen === 'pos' && 'P.O.S') ||
          (selectedScreen === 'inventory' && inventorySelected) ||
          (selectedScreen === 'inventory' && addItem && 'Add Item') ||
          selectedScreen // Fallback to selectedScreen if no title found
        ) : (
          <div>
            <div
              onClick={() => {
                navigate(-1); // Navigate back to the previous page
                setItemDetails(false);
              }}
              className='flex items-center justify-center gap-2 text-[16px] bg-white hover:bg-primary hover:text-white w-fit p-2 rounded-lg shadow-lg cursor-pointer'
            >
              <IoChevronBack />
              <p className=''>{inventorySelected}</p>
            </div>
          </div>
        )}
      </div>
      {selectedScreen === 'dashboard' && <Dashboard />}
      {selectedScreen === 'pos' && <POS />}
      {selectedScreen === 'inventory' && <Inventory />}
      {selectedScreen === 'orders' && <Orders />}
      {selectedScreen === 'purchaseorders' && <PurchaseOrders />}
      {/* {selectedScreen === 'settings' && <Settings />} */}
      {selectedScreen === 'users' && <Users />}
      {selectedScreen === 'reports' && <Reports />}
    </div>
  );
};

export default Content;
