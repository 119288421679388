import React from 'react';

const Button = ({ label, onClick, className, disabled }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled || false}
      className={`capitalize font-light font-urbanist ${className}`}
    >
      {label}
    </button>
  );
};

export default Button;
