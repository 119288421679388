import React from 'react';
import { IoClose } from 'react-icons/io5';

const LowStock = ({ lowStockItems, closeLowStock }) => {
  return (
    <div className='fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75'>
      <div
        className='flex flex-col gap-2 bg-white px-2 pt-4 overflow-auto w-[90%] md:w-[50%] rounded-lg'
        style={{ maxHeight: 'calc(100vh - 200px)' }}
      >
        <div className='flex items-center justify-end '>
          <h2 className='flex-1 text-center text-gray-800 text-2xl'>
            Low Stock
          </h2>
          <div
            className='mr-2 cursor-pointer text-red-600 hover:bg-red-600 hover:text-white p-1 rounded-md border border-red-600 text-xl'
            onClick={closeLowStock}
          >
            <IoClose />
          </div>
        </div>
        <div className='my-3'>
          {lowStockItems.map((item, index) => (
            <div
              key={index}
              className={`flex items-center justify-between p-2 m-1 hover:bg-gray-200 cursor-pointer rounded-lg ${
                (index + 1) % 2 === 0 ? 'bg-gray-100' : 'bg-white'
              }`}
            >
              <div className='flex items-center justify-between gap-4 capitalize'>
                <p>{index + 1}</p>
                <p>{item.name}</p>
              </div>
              <div>
                {item.stock}{' '}
                {item.stock === 0 || item.stock > 1 ? 'units' : 'unit'}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LowStock;
