import React, { useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import SideBar from './SideBar';
import TopBar from './TopBar';
import Content from './Content';
import { InventoryContext } from '../context/InventoryProvider';

const Dashboard = () => {
  const {
    selectedScreen,
    setSelectedScreen,
    inventorySelected,
    setInventorySelected,
    isMobileMenuOpen,
    setIsMobileMenuOpen,
    toggleMobileMenu,
    handleScreenSelection,
    handleInventorySelection,
    setItemSelected,
    setItemDetails,
  } = useContext(InventoryContext);

  const location = useLocation();
  // const { item } = useParams();

  // Update the URL based on the selected screen
  useEffect(() => {
    const currentPath = location.pathname.split('/')[2];

    setSelectedScreen(currentPath);
  }, [location.pathname]);

  return (
    <div className='bg-background'>
      <div className='max-w-[1440px] m-auto '>
        <div className='sticky top-2 mx-2 z-30'>
          <TopBar
            isMobileMenuOpen={isMobileMenuOpen}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
            toggleMobileMenu={toggleMobileMenu}
          />
        </div>
        <div className='flex gap-2 mx-2'>
          <div>
            <div
              className={`flex-1 bg-white sticky shadow-lg rounded-lg top-[82px] ${
                isMobileMenuOpen ? 'z-40' : ''
              }`}
              style={{ height: 'calc(100vh - 82px)' }}
              onClick={() => {
                setItemDetails(false);
                setItemSelected('');
              }}
            >
              <SideBar
                selectedScreen={selectedScreen}
                setSelectedScreen={setSelectedScreen}
                handleScreenSelection={handleScreenSelection}
                isMobileMenuOpen={isMobileMenuOpen}
                setIsMobileMenuOpen={setIsMobileMenuOpen}
                toggleMobileMenu={toggleMobileMenu}
                inventorySelected={inventorySelected}
                setInventorySelected={setInventorySelected}
                handleInventorySelection={handleInventorySelection}
              />
            </div>
          </div>

          <div
            className={`flex-[6] md:flex-[4] rounded-lg ${
              isMobileMenuOpen ? 'z-20' : ''
            }`}
          >
            <Content />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
