import React, { useContext, useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import Button from './Button';
import { IoArrowDown, IoArrowUp } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { InventoryContext } from '../context/InventoryProvider';
import axios from 'axios';

const InventoryAnalytics = () => {
  const { selectedScreen, handleInventorySelection, setAddItem } =
    useContext(InventoryContext);

  const [inventoryList, setInventoryList] = useState();

  const token = localStorage.getItem('token');

  // Fetch the inventory from the db
  useEffect(() => {
    const fetchInventoryList = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/inventory`,
          {
            headers: {
              token: `Bearer ${token}`,
            },
          }
        );
        const data = response.data;

        setInventoryList(data);
      } catch (err) {
        console.error('Error fetching inventory:', err);
      } finally {
        // setLoading(false);
      }
    };
    fetchInventoryList();
  }, [selectedScreen]);

  // Define categories
  const categories = [
    'Tablets and Capsules',
    'Liquids and Vials',
    'Supplements and Vitamins',
    'Personal Protective Equipment (PPE)',
    'Creams and Lotions',
    'Ear, Eyes and Nose Drops',
    'Syrups',
    'Medical Equipment',
    'Others',
  ];

  // Initialize inventory analytics using map
  const inventoryAnalytics = categories.map((category) => ({
    category,
    stockAdded: 0,
    stock: 0,
  }));

  // Calculate total quantities for each category
  inventoryList?.forEach((item) => {
    const categoryAnalytics = inventoryAnalytics.find(
      (analytics) => analytics.category === item?.category
    );

    if (categoryAnalytics) {
      categoryAnalytics.stock += item?.stock;
    }

    if (categoryAnalytics) {
      categoryAnalytics.stockAdded += item?.stockAdded;
    }
  });

  // Mapping for short forms
  const categoryShortForms = {
    'Tablets and Capsules': 'Tabs & Caps',
    'Liquids and Vials': 'Liquids',
    'Supplements and Vitamins': 'Supplements',
    'Personal Protective Equipment (PPE)': 'PPE',
    'Creams and Lotions': 'Creams',
    'Ear, Eyes and Nose Drops': 'EEN Drops',
    Syrups: 'Syrups',
    'Medical Equipment': 'Med Equip',
    Others: 'Others',
  };

  const data = inventoryAnalytics.map((category) => ({
    name: categoryShortForms[category.category],
    items: category.stock,
  }));

  // Calculate percentage change
  const calculatePercentageChange = (currentMonth, lastMonth) => {
    if (lastMonth === 0) {
      return currentMonth > 0 ? 100 : 0;
    }
    return ((currentMonth - lastMonth) / lastMonth) * 100;
  };

  return (
    <div>
      {/* Add New Item Button */}
      <div className='mb-5 w-fit' onClick={() => setAddItem(true)}>
        <Link to='/pharmacy/inventory/add_item'>
          <Button
            label='Add New Item'
            className='bg-blue-600 hover:bg-accent text-white py-2 px-4 rounded-lg cursor-pointer'
          />
        </Link>
      </div>

      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5'>
        {/* Category Stats */}
        {inventoryAnalytics.map((category, index) => (
          <Link
            key={index}
            to={`/pharmacy/inventory/${index}`}
            className='flex-1 p-5 text-center bg-white rounded-lg shadow-lg cursor-pointer'
          >
            <div onClick={() => handleInventorySelection(category.category)}>
              <h6 className='text-xl font-light font-urbanist truncate text-left'>
                {category.category}
              </h6>
              <div className='flex items-center justify-between'>
                <div className='flex-[4] flex items-end gap-2 mt-3 text-text'>
                  <h5 className='text-3xl'>
                    {new Intl.NumberFormat('en-US').format(category.stock)}
                  </h5>
                  <p className='text-sm pb-[2px]'>Items</p>
                </div>
                <div
                  className={`flex items-center justify-center ${
                    calculatePercentageChange(
                      category.stock,
                      category.stockAdded
                    ) >= 0
                      ? 'text-success'
                      : 'text-error'
                  }`}
                >
                  <p>
                    {calculatePercentageChange(
                      category.stock,
                      category.stockAdded
                    ).toFixed(0)}
                    %
                  </p>
                  <p className='text-xl '>
                    {calculatePercentageChange(
                      category.stock,
                      category.stockAdded
                    ) >= 0 ? (
                      <IoArrowUp />
                    ) : (
                      <IoArrowDown />
                    )}
                  </p>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>

      {/* Bar Chart for Analytics */}
      <div className='p-5 mt-5 bg-white rounded-lg shadow-lg'>
        <p className='mb-5 text-center text-xl'>Inventory</p>
        <ResponsiveContainer width='100%' height={300}>
          <BarChart data={data}>
            <XAxis dataKey='name' />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey='items' fill='rgba(75, 192, 192, 0.6)' />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default InventoryAnalytics;
