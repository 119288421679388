import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { OrderContext } from '../../context/OrderProvider';
import DashboardLineChart from '../../components/DashboardLineChart';
import { IoArrowDown, IoArrowUp } from 'react-icons/io5';
// import api from 'axios';
import { InventoryContext } from '../../context/InventoryProvider';
import LowStock from '../../components/LowStock';
import api, { setupInterceptors } from '../../api/api';
import Expiring from '../../components/Expiring';

const Dashboard = () => {
  const {
    setPageSelected,
    orders,
    setOrders,
    suppliers,
    setSuppliers,
    orderStatusChange,
    pageSelected,
  } = useContext(OrderContext);
  const {
    inventoryList,
    setInventoryList,
    selectedScreen,
    inventorySelected,
    addItem,
    processingSale,
  } = useContext(InventoryContext);

  const suppliersCount = suppliers && suppliers.length;
  const ordersCount = orders && orders.length;
  const pendingDeliveries =
    orders && orders.filter((order) => order.status === 'pending').length;

  const [sales, setSales] = useState([]);
  const [salesChartData, setSalesChartData] = useState([]);
  const [totalSalesThisMonth, setTotalSalesThisMonth] = useState(0);
  const [totalSalesLastMonth, setTotalSalesLastMonth] = useState(0);
  const [salesPercentageChange, setSalesPercentageChange] = useState(0);

  const [ordersChartData, setOrdersChartData] = useState([]);
  const [totalOrdersThisMonth, setTotalOrdersThisMonth] = useState(0);
  const [totalOrdersLastMonth, setTotalOrdersLastMonth] = useState(0);
  const [ordersPercentageChange, setOrdersPercentageChange] = useState(0);

  const [inventoryChartData, setInventoryChartData] = useState([]);
  const [totalInventoryThisMonth, setTotalInventoryThisMonth] = useState(0);
  const [totalInventoryLastMonth, setTotalInventoryLastMonth] = useState(0);
  const [inventoryPercentageChange, setInventoryPercentageChange] = useState(0);

  const [lowStock, setLowStock] = useState(false);
  const [expiring, setExpiring] = useState(false);

  const now = new Date();
  const currentMonthStart = new Date(now.getFullYear(), now.getMonth(), 1);
  const lastMonthStart = new Date(now.getFullYear(), now.getMonth() - 1, 1);
  const lastMonthEnd = new Date(now.getFullYear(), now.getMonth(), 0);

  const token = localStorage.getItem('token');

  const navigate = useNavigate();

  useEffect(() => {
    // Call setupInterceptors and pass the navigate function
    setupInterceptors(navigate);
  }, [navigate]);

  // Calculate percentage change
  const calculatePercentageChange = (lastMonth, previousMonth) => {
    if (previousMonth === 0) {
      return lastMonth > 0 ? 100 : 0;
    }
    return ((lastMonth - previousMonth) / previousMonth) * 100;
  };

  // Fetch the inventory from the db
  useEffect(() => {
    const fetchInventoryList = async () => {
      try {
        const response = await api.get(
          `${process.env.REACT_APP_API_URL}/inventory`,
          {
            headers: {
              token: `Bearer ${token}`,
            },
          }
        );
        const data = response.data;

        setInventoryList(data);
      } catch (err) {
        console.error('Error fetching inventory:', err);
      } finally {
        // setLoading(false);
      }
    };
    fetchInventoryList();
  }, [selectedScreen, inventorySelected, addItem, processingSale]);

  // Fetch the orders from the db
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await api.get(
          `${process.env.REACT_APP_API_URL}/purchase-orders`,
          {
            headers: {
              token: `Bearer ${token}`,
            },
          }
        );
        const data = response.data;

        setOrders(data);
      } catch (err) {
        console.error('Error fetching orders:', err);
      } finally {
        // setLoading(false);
      }
    };
    fetchOrders();
  }, [orderStatusChange]);

  // Fetch the suppliers from the db
  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const response = await api.get(
          `${process.env.REACT_APP_API_URL}/suppliers`,
          {
            headers: {
              token: `Bearer ${token}`,
            },
          }
        );
        const data = response.data;

        setSuppliers(data);
      } catch (err) {
        console.error('Error fetching suppliers:', err);
      } finally {
        // setLoading(false);
      }
    };
    fetchSuppliers();
  }, [pageSelected]);

  // Fetch the sales from the db
  useEffect(() => {
    const fetchSales = async () => {
      try {
        const response = await api.get(
          `${process.env.REACT_APP_API_URL}/sales`,
          {
            headers: {
              token: `Bearer ${token}`,
            },
          }
        );
        const data = response.data;
        setSales(data);
        calculateSalesChartData(data); // Calculate chart data after fetching sales
      } catch (err) {
        console.error('Error fetching sales:', err);
      }
    };
    fetchSales();
  }, []);

  // Fetch the orders from the db
  useEffect(() => {
    orders && calculateOrdersChartData(orders); // Calculate chart data after fetching orders
  }, [orders]);

  // Fetch the inventory from the db
  useEffect(() => {
    inventoryList && calculateInventoryChartData(); // Calculate chart data after fetching inventory list
  }, [inventoryList]);

  // Calculate sales data for the last 6 months
  const calculateSalesChartData = (salesData) => {
    const now = new Date();
    const lastSixMonthsData = Array(6).fill(0); // Array to hold total sales for the last 6 months

    // Loop through the last 6 months
    for (let i = 0; i < 6; i++) {
      const monthStart = new Date(now.getFullYear(), now.getMonth() - i, 1); // First day of the month
      const monthEnd = new Date(now.getFullYear(), now.getMonth() - i + 1, 0); // Last day of the month

      // Calculate total sales for the month
      const totalSalesForMonth = salesData.reduce((acc, sale) => {
        const saleDate = new Date(sale.createdAt);
        if (saleDate >= monthStart && saleDate <= monthEnd) {
          return acc + sale.totalAmount;
        }
        return acc;
      }, 0);

      lastSixMonthsData[i] = totalSalesForMonth;
    }

    // Prepare the sales chart data
    const formattedChartData = lastSixMonthsData.map((total, index) => {
      const monthName = new Date(
        now.getFullYear(),
        now.getMonth() - index,
        1
      ).toLocaleString('default', { month: 'long' });
      return { name: monthName, sales: total };
    });

    setSalesChartData(formattedChartData.reverse()); // Reverse to show the most recent month first
  };

  // Calculate orders data for the last 6 months
  const calculateOrdersChartData = (ordersData) => {
    const now = new Date();
    const lastSixMonthsData = Array(6).fill(0); // Array to hold total orders for the last 6 months

    // Loop through the last 6 months
    for (let i = 0; i < 6; i++) {
      const monthStart = new Date(now.getFullYear(), now.getMonth() - i, 1); // First day of the month
      const monthEnd = new Date(now.getFullYear(), now.getMonth() - i + 1, 0); // Last day of the month

      // Calculate total orders for the month
      const totalOrdersForMonth = ordersData.reduce((acc, order) => {
        const orderDate = new Date(order.createdAt); // Assuming order.createdAt is the date field
        if (orderDate >= monthStart && orderDate <= monthEnd) {
          return acc + 1; // Count the order
        }
        return acc;
      }, 0);

      lastSixMonthsData[i] = totalOrdersForMonth; // Store the total orders for the month
    }

    // Prepare the orders chart data
    const formattedChartData = lastSixMonthsData.map((total, index) => {
      const monthName = new Date(
        now.getFullYear(),
        now.getMonth() - index,
        1
      ).toLocaleString('default', { month: 'long' });
      return { name: monthName, orders: total };
    });

    setOrdersChartData(formattedChartData.reverse()); // Reverse to show the most recent month first
  };

  // Calculate orders data for the last 6 months
  const calculateInventoryChartData = () => {
    const now = new Date();
    const lastTwoMonthsData = Array(2).fill(0); // Array to hold total inventory for the last 2 months

    // Set the inventory for the last two months
    lastTwoMonthsData[lastTwoMonthsData.length - 2] = totalInventoryThisMonth;
    lastTwoMonthsData[lastTwoMonthsData.length - 1] = totalInventoryLastMonth;

    // Prepare the chart data with months and total inventory
    const formattedChartData = lastTwoMonthsData.map((total, index) => {
      const monthName = new Date(
        now.getFullYear(),
        now.getMonth() - index,
        1
      ).toLocaleString('default', { month: 'long' });
      return { name: monthName, items: total };
    });

    setInventoryChartData(formattedChartData.reverse()); // Reverse to show the most recent month first
  };

  // Calculate total sales for the last month and the previous month and the percentage change
  useEffect(() => {
    const calculateTotalSalesThisMonth = () => {
      const totalThisMonth = sales.reduce((acc, sale) => {
        const saleDate = new Date(sale.createdAt);
        // Check if the sale is from the last month
        if (saleDate >= currentMonthStart && saleDate <= now) {
          return acc + sale.totalAmount; // Assuming sale.totalAmount is the sales amount
        }
        return acc;
      }, 0);

      setTotalSalesThisMonth(totalThisMonth);
    };

    const calculateTotalSalesLastMonth = () => {
      const totalLastMonth = sales.reduce((acc, sale) => {
        const saleDate = new Date(sale.createdAt);
        // Check if the sale is from the previous month
        if (saleDate >= lastMonthStart && saleDate <= lastMonthEnd) {
          return acc + sale.totalAmount; // Assuming sale.totalAmount is the sales amount
        }
        return acc;
      }, 0);

      setTotalSalesLastMonth(totalLastMonth);
    };

    if (sales && sales.length > 0) {
      calculateTotalSalesThisMonth();
      calculateTotalSalesLastMonth();
    }

    const lastMonthTotal = totalSalesThisMonth;
    const previousMonthTotal = totalSalesLastMonth;
    const salesPercentageChange = calculatePercentageChange(
      lastMonthTotal,
      previousMonthTotal
    );

    setSalesPercentageChange(salesPercentageChange);
  }, [sales, totalSalesLastMonth, totalSalesThisMonth]);

  // Calculate total orders for the current month and the previous month
  useEffect(() => {
    const calculateTotalOrders = () => {
      const ordersThisMonth = orders.filter((order) => {
        const orderDate = new Date(order.createdAt);
        return orderDate >= currentMonthStart && orderDate < now; // Orders from the current month
      });

      const ordersLastMonth = orders.filter((order) => {
        const orderDate = new Date(order.createdAt);
        return orderDate >= lastMonthStart && orderDate <= lastMonthEnd; // Orders from the last month
      });

      setTotalOrdersThisMonth(ordersThisMonth.length);
      setTotalOrdersLastMonth(ordersLastMonth.length);
    };

    if (orders && orders.length > 0) {
      calculateTotalOrders();
    }

    const lastMonthTotal = totalOrdersThisMonth;
    const previousMonthTotal = totalOrdersLastMonth;
    const percentageChange = calculatePercentageChange(
      lastMonthTotal,
      previousMonthTotal
    );

    setOrdersPercentageChange(percentageChange);
  }, [orders, totalOrdersThisMonth, totalOrdersLastMonth]);

  // Calculate total inventory for the current month and the previous month
  useEffect(() => {
    const calculateTotalInventory = () => {
      // Calculate the total inventory for this and last month
      const inventoryThisMonth = inventoryList.reduce(
        (total, item) => total + item.stock,
        0
      );
      const inventoryLastMonth = inventoryList.reduce(
        (total, item) => total + item.lastMonthStock,
        0
      );

      setTotalInventoryThisMonth(inventoryThisMonth);
      setTotalInventoryLastMonth(inventoryLastMonth);

      // Calculate the percentage change between this month and last month
      const percentageChange = calculatePercentageChange(
        inventoryThisMonth,
        inventoryLastMonth
      );

      setInventoryPercentageChange(percentageChange);
    };

    if (inventoryList && inventoryList.length > 0) {
      calculateTotalInventory();
    }
  }, [inventoryList, currentMonthStart, lastMonthStart, lastMonthEnd, now]);

  // Items low in stock
  const lowStockItems =
    inventoryList &&
    inventoryList.filter((item) => {
      const category = item.category;
      const stock = item.stock;

      if (
        category === 'Tablets and Capsules' ||
        category === 'Supplements and Vitamins' ||
        category === 'Liquids and Vials' ||
        category === 'Others'
      ) {
        return stock < 50;
      }

      if (
        category === 'Medical Equipment' ||
        category === 'Syrups' ||
        category === 'Creams and Lotions' ||
        category === 'Ear, Eyes and Nose Drops'
      ) {
        return stock < 5;
      }

      if (category === 'Personal Protective Equipment (PPE)') {
        return stock < 10;
      }

      return false; // For categories not specified, no low stock criteria
    });

  // Items that are nearing expiry
  const nearingExpiry =
    inventoryList &&
    inventoryList.filter((item) => {
      const setDaysToExpiry = 30;
      const expiryDate = new Date(item.dateOfExpiry);

      // Calculate the difference in time between expiry date and now
      const timeDifference = expiryDate - now;

      // Convert the time difference to days
      const daysToExpire = timeDifference / (1000 * 60 * 60 * 24);

      // Return items expiring within the defined "daysToExpiry" range
      return daysToExpire > 0 && daysToExpire <= setDaysToExpiry;
    });

  // Items that are expired
  const expiredItems =
    inventoryList &&
    inventoryList.filter((item) => {
      const expiryDate = new Date(item.dateOfExpiry);
      return expiryDate < now;
    });

  const closeLowStock = () => {
    setLowStock(false);
    setExpiring(false);
  };

  return (
    <div className='overflow-auto rounded-lg mt-5'>
      <div className='' style={{ height: 'calc(100vh - 140px)' }}>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
          {/* Sales Card */}
          <Link to='/pharmacy/reports' className='cursor-pointer'>
            <div className='bg-white p-4 rounded-lg shadow-lg'>
              <div className='flex items-center justify-between mb-2'>
                <h2 className='text-xl font-light font-urbanist truncate text-left'>
                  Total Sales
                </h2>
                <p className='hover:underline text-blue-500 text-sm'>
                  View Report
                </p>
              </div>
              <div className='flex items-start justify-between'>
                <div>
                  <p className='text-3xl text-text'>
                    {new Intl.NumberFormat('en-US').format(totalSalesThisMonth)}{' '}
                    <span className='text-sm'>UGX</span>
                  </p>
                </div>
                <div
                  className={`flex items-center justify-center ${
                    salesPercentageChange >= 0 ? 'text-success' : 'text-error'
                  }`}
                >
                  <p>{salesPercentageChange.toFixed(0)}%</p>
                  <p className='text-xl '>
                    {salesPercentageChange >= 0 ? (
                      <IoArrowUp />
                    ) : (
                      <IoArrowDown />
                    )}
                  </p>
                </div>
              </div>
              <DashboardLineChart data={salesChartData} dataKey='sales' />
            </div>
          </Link>
          {/* Inventory Card */}
          <Link to='/pharmacy/inventory' className='cursor-pointer'>
            <div className='bg-white p-4 rounded-lg shadow-lg'>
              <div className='flex items-center justify-between mb-2'>
                <h2 className='text-xl font-light font-urbanist truncate text-left'>
                  Total Inventory Items
                </h2>
                <p className='hover:underline text-blue-500 text-sm'>
                  View Inventory
                </p>
              </div>
              <div className='flex items-start justify-between'>
                <div>
                  <p className='text-3xl text-text'>
                    {new Intl.NumberFormat('en-US').format(
                      totalInventoryThisMonth
                    )}
                  </p>
                </div>
                <div
                  className={`flex items-center justify-center ${
                    inventoryPercentageChange >= 0
                      ? 'text-success'
                      : 'text-error'
                  }`}
                >
                  <p>{inventoryPercentageChange.toFixed(0)}%</p>
                  <p className='text-xl '>
                    {inventoryPercentageChange >= 0 ? (
                      <IoArrowUp />
                    ) : (
                      <IoArrowDown />
                    )}
                  </p>
                </div>
              </div>
              <DashboardLineChart data={inventoryChartData} dataKey='items' />
            </div>
          </Link>
          {/* Orders Card */}

          <Link
            to='/pharmacy/orders/orders'
            onClick={() => setPageSelected('orders')}
            className='cursor-pointer'
          >
            <div className='bg-white p-4 rounded-lg shadow-lg'>
              <div className='flex items-center justify-between mb-2'>
                <h2 className='text-xl font-light font-urbanist truncate text-left'>
                  Total Orders
                </h2>
                <p className='hover:underline text-blue-500 text-sm'>
                  View Orders
                </p>
              </div>
              <div className='flex items-start justify-between'>
                <div>
                  <p className='text-3xl text-text'>{ordersCount}</p>
                </div>
                <div
                  className={`flex items-center justify-center ${
                    ordersPercentageChange >= 0 ? 'text-success' : 'text-error'
                  }`}
                >
                  <p>{ordersPercentageChange.toFixed(0)}%</p>
                  <p className='text-xl '>
                    {ordersPercentageChange >= 0 ? (
                      <IoArrowUp />
                    ) : (
                      <IoArrowDown />
                    )}
                  </p>
                </div>
              </div>
              <DashboardLineChart data={ordersChartData} dataKey='orders' />
            </div>
          </Link>

          {/* Suppliers Card */}
          <Link
            to='/pharmacy/orders/suppliers'
            className='cursor-pointer'
            onClick={() => setPageSelected('suppliers')}
          >
            <div className='bg-white p-4 rounded-lg shadow-lg'>
              <div className='flex items-center justify-between mb-2'>
                <h2 className='text-xl font-light font-urbanist truncate text-left'>
                  Total Suppliers
                </h2>
                <p className='hover:underline text-blue-500 text-sm'>
                  View Suppliers
                </p>
              </div>
              <h2 className='text-xl font-light font-urbanist truncate text-left'></h2>
              <p className='text-3xl text-text'>{suppliersCount}</p>
            </div>
          </Link>
          {/* Additional Cards can be added here */}
          <Link
            to='/pharmacy/orders/orders'
            className='cursor-pointer'
            onClick={() => setPageSelected('orders')}
          >
            <div className='bg-white p-4 rounded-lg shadow-lg'>
              <div className='flex items-center justify-between mb-2'>
                <h2 className='text-xl font-light font-urbanist truncate text-left'>
                  Pending Deliveries
                </h2>
                <p className='hover:underline text-blue-500 text-sm'>
                  View Deliveries
                </p>
              </div>
              <p className='text-3xl text-text'>{pendingDeliveries}</p>
            </div>
          </Link>
          <div className='flex flex-col md:flex-row items-center justify-between gap-4'>
            <div
              className='flex-1 cursor-pointer'
              onClick={() => setLowStock(true)}
            >
              <div className='bg-white p-4 rounded-lg shadow-lg'>
                <div className='flex items-center justify-between mb-2'>
                  <h2 className='text-xl font-light font-urbanist truncate text-left'>
                    Low Stock
                  </h2>
                  <p className='hover:underline text-blue-500 text-sm'>
                    View
                    {/* View Low Stock */}
                  </p>
                </div>
                <p className='text-3xl text-error'>
                  {inventoryList && lowStockItems.length}{' '}
                  <span className='text-sm'>
                    {inventoryList &&
                      (lowStockItems.length === 0 || lowStockItems.length > 1
                        ? 'Items'
                        : 'Item')}
                  </span>
                </p>
              </div>
            </div>
            <div
              className='flex-1 cursor-pointer'
              onClick={() => setExpiring(true)}
            >
              <div className='bg-white p-4 rounded-lg shadow-lg'>
                <div className='flex items-center justify-between mb-2'>
                  <h2 className='text-xl font-light font-urbanist truncate text-left'>
                    Expiring Stock
                  </h2>
                  <p className='hover:underline text-blue-500 text-sm'>
                    View
                    {/* View Low Stock */}
                  </p>
                </div>
                <p className='text-3xl text-error'>
                  {inventoryList && nearingExpiry.length}{' '}
                  <span className='text-sm'>
                    {inventoryList &&
                      (lowStockItems.length === 0 || lowStockItems.length > 1
                        ? 'Items'
                        : 'Item')}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='z-40'>
        {lowStock && inventoryList && lowStockItems && (
          <LowStock
            lowStockItems={lowStockItems}
            closeLowStock={closeLowStock}
          />
        )}
        {expiring && inventoryList && nearingExpiry && (
          <Expiring
            nearingExpiry={nearingExpiry}
            expiredItems={expiredItems}
            closeLowStock={closeLowStock}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
