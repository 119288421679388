import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { facilityDetails } from '../constants/facility';

// Define styles
const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 10,
    fontFamily: 'Helvetica',
  },
  pageTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    marginBottom: 10,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginVertical: 10,
  },
  text: {
    marginBottom: 10,
  },
  tableContainer: {
    marginVertical: 10,
  },
  tableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#000',
    marginBottom: 5,
  },
  tableRow: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  tableColHeader: {
    width: '33%',
    fontWeight: 'bold',
  },
  tableCol: {
    width: '33%',
  },
  chartPlaceholder: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 230,
    backgroundColor: '#f0f0f0',
    marginBottom: 10,
    textAlign: 'center',
    borderRadius: 10,
  },
  chartHeading: { paddingVertical: 20, fontSize: 12 },
  lineAndPie: { display: 'flex', flexDirection: 'row', gap: 10 },
  salesSummary: { display: 'flex', flexDirection: 'row' },
});

// The PDF Document component
const PharmacySalesReport = ({ startDate, endDate, reportData, user }) => {
  // Calculate total quantities sold
  const totalQuantities = reportData.reduce((acc, transaction) => {
    transaction.cartItems.forEach((item) => {
      acc[item.name] = (acc[item.name] || 0) + item.quantity; // Accumulate quantities by product name
    });
    return acc;
  }, {});

  // Convert the total quantities object to an array for easier rendering
  const totalQuantitiesArray = Object.entries(totalQuantities).map(
    ([name, quantity]) => ({
      name,
      quantity,
    })
  );

  // Sort the totalQuantitiesArray in descending order based on quantity
  const sortedQuantities = totalQuantitiesArray.sort(
    (a, b) => b.quantity - a.quantity
  );
  const topFiveItems = sortedQuantities.slice(0, 5);

  return (
    <Document>
      {/* Page 1: Sales Summary */}
      <Page size='A4' style={styles.page}>
        <View style={styles.pageTitle}>
          <Text style={styles.header}>{facilityDetails.name}</Text>
          <Text style={styles.text}>Pharmacy Sales Report</Text>
          <Text style={styles.text}>
            {startDate} to {endDate}
          </Text>
        </View>

        <View
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            gap: 5,
            marginVertical: 20,
            fontWeight: 'bold',
            fontSize: 12,
          }}
        >
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              gap: 5,
            }}
          >
            <Text>Report Created On:</Text>
            <Text> {new Date().toISOString().split('T')[0]}</Text>
          </View>
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              gap: 5,
            }}
          >
            <Text>Created By:</Text>
            <Text>
              {' '}
              {user && user?.firstName} {user && user?.lastName}
            </Text>
          </View>
        </View>

        {/* Sales Summary */}
        <View style={styles.salesSummary}>
          <View style={[{ flex: 1 }, styles.summary]}>
            <Text style={styles.sectionTitle}>Sales Summary</Text>
            <Text style={styles.text}>
              Total Sales: UGX{' '}
              {new Intl.NumberFormat('en-US').format(
                reportData.reduce(
                  (total, transaction) => total + transaction.totalAmount,
                  0
                )
              )}
            </Text>
            <Text style={styles.text}>
              Total Transactions: {reportData.length}
            </Text>
            <Text style={styles.text}>
              Total Items Sold:{' '}
              {totalQuantitiesArray.reduce(
                (total, item) => total + item.quantity,
                0
              )}
            </Text>
            <Text style={styles.text}>Sales by Payment Method:</Text>
            <Text style={styles.text}>
              Cash: 60%, Card: 30%, Mobile Money: 10%
            </Text>
          </View>

          {/* Top Selling Products */}
          <View style={[{ flex: 1 }, styles.topSelling]}>
            <Text style={styles.sectionTitle}>Top Selling Products</Text>
            {topFiveItems.map((item) => (
              <Text key={item.name} style={styles.text}>
                {item.name}: {item.quantity} Units
              </Text>
            ))}
          </View>
        </View>

        {/* Placeholder for charts */}
        <View style={styles.lineAndPie}>
          <View style={styles.chartPlaceholder}>
            <Text style={styles.chartHeading}>Sales Trend (Line Chart)</Text>
          </View>

          <View style={styles.chartPlaceholder}>
            <Text style={styles.chartHeading}>
              Sales by Category (Pie Chart)
            </Text>
          </View>
        </View>

        <View style={styles.chartPlaceholder}>
          <Text style={styles.chartHeading}>Top 5 Products (Bar Chart)</Text>
        </View>
      </Page>

      {/* Page 2: Condensed Sales Data */}
      <Page size='A4' style={styles.page}>
        <Text style={styles.sectionTitle}>Summary Data per Product</Text>
        {/* Table */}
        <View style={styles.tableContainer}>
          {/* Table Header */}
          <View style={styles.tableHeader}>
            <Text style={styles.tableColHeader}>Product Name</Text>
            <Text style={styles.tableColHeader}>Total Sold</Text>
            <Text style={styles.tableColHeader}>Total Revenue (UGX) </Text>
          </View>

          {/* Table Rows */}
          {totalQuantitiesArray.map((item) => (
            <View key={item.name} style={styles.tableRow}>
              <Text style={styles.tableCol}>{item.name}</Text>
              <Text style={styles.tableCol}>{item.quantity} units</Text>
              <Text style={styles.tableCol}>
                {new Intl.NumberFormat('en-US').format(
                  item.quantity *
                    (reportData
                      .find((transaction) =>
                        transaction.cartItems.some(
                          (cartItem) => cartItem.name === item.name
                        )
                      )
                      ?.cartItems.find(
                        (cartItem) => cartItem.name === item.name
                      )?.unitPrice || 0)
                )}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default PharmacySalesReport;
