import React, { useContext, useState } from 'react';
import InputField from './InputField';
import { OrderContext } from '../context/OrderProvider';
import Button from './Button';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import colors from '../assets/colors';

const AddSupplier = () => {
  const { setPageSelected } = useContext(OrderContext);

  const token = localStorage.getItem('token');

  const [supplier, setSupplier] = useState({
    name: '',
    contactPerson: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    country: '',
  });

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'phone') {
      // Regular expression for phone validation
      const phonePattern = /^(\+)?[0-9]*$/;
      // Allow only numbers and optional "+" at the start
      if (phonePattern.test(value)) {
        setSupplier({ ...supplier, [name]: value });
      }
    } else {
      setSupplier({ ...supplier, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newSupplier = {
      name: supplier.name,
      contactPerson: supplier.contactPerson,
      phone: supplier.phone,
      email: supplier.email,
      address: supplier.address,
      city: supplier.city,
      country: supplier.country,
    };

    // Show a "Saving..." toast notification while data is being processed
    const savingToastId = toast.info(`Adding Supplier ...`, {
      position: 'top-center',
      autoClose: false, // Keep it open until dismissed manually
      style: {
        backgroundColor: '#FFD700', // Customize the background color (yellowish for "saving")
        color: '#000',
        textAlign: 'center',
      },
      icon: false,
      hideProgressBar: true,
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/suppliers`,
        newSupplier,
        {
          headers: {
            token: `Bearer ${token}`,
          },
        }
      );

      // If the request was successful
      if (response.status === 201 || response.status === 200) {
        const savedSupplier = response.data;

        // Dismiss the "Saving..." toast
        toast.dismiss(savingToastId);

        toast.success(`${savedSupplier.name} added successfully!`, {
          position: 'top-center',
          autoClose: 500,
          // Styling the pop-up message
          style: {
            backgroundColor: `${colors.success}`,
            color: '#fff',
            textAlign: 'center',
          },
          icon: false,
          // Remove the progress bar
          hideProgressBar: true,
        });

        // Reset form after successful submission
        handleClear();
      } else {
        toast.error(`Failed to add supplier:, ${response.data.message} `, {
          position: 'top-center',
          icon: false,
          // Remove the progress bar
          hideProgressBar: true,
          // Styling the pop-up message
          style: {
            backgroundColor: `${colors.error}`,
            color: '#fff',
            textAlign: 'center',
          },
        });
      }
    } catch (error) {
      toast.error(`Error while adding supplier: ${error}} `, {
        position: 'top-center',
        icon: false,
        // Remove the progress bar
        hideProgressBar: true,
        // Styling the pop-up message
        style: {
          backgroundColor: `${colors.error}`,
          color: '#fff',
          textAlign: 'center',
        },
      });
    }
  };

  const handleClear = () => {
    setSupplier({
      name: '',
      contactPerson: '',
      phone: '',
      email: '',
      address: '',
      city: '',
      country: '',
    });
  };

  const handleCancel = () => {
    setPageSelected(''); // This will cancel the form or action
  };

  return (
    <div className='p-4 bg-white shadow-md rounded-lg'>
      <p className='flex items-center justify-center mb-5 text-2xl text-primary font-light'>
        Enter Supplier Details
      </p>
      <form onSubmit={handleSubmit}>
        <div className='mb-4'>
          <InputField
            label='Supplier Name'
            type='text'
            name='name'
            value={supplier.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className='mb-4'>
          <InputField
            label='Contact Person'
            type='text'
            name='contactPerson'
            value={supplier.contactPerson}
            onChange={handleChange}
            required
          />
        </div>
        <div className='flex flex-col md:flex-row gap-4 mb-4'>
          <InputField
            label='Phone'
            type='tel'
            name='phone'
            value={supplier.phone}
            onChange={handleChange}
            required
          />
          <InputField
            label='Email'
            type='email'
            name='email'
            value={supplier.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className='flex flex-col md:flex-row gap-4 mb-4'>
          <InputField
            label='Address'
            type='text'
            name='address'
            value={supplier.address}
            onChange={handleChange}
          />
          <InputField
            label='City'
            type='text'
            name='city'
            value={supplier.city}
            onChange={handleChange}
          />
          <InputField
            label='Country'
            type='text'
            name='country'
            value={supplier.country}
            onChange={handleChange}
          />
        </div>
        <div className='flex flex-col md:flex-row items-center gap-5'>
          <Button
            label='Clear Form'
            onClick={handleClear}
            className='px-3 py-2 w-full md:w-fit border-2 border-warning bg-warning/10 text-warning text-lg hover:bg-warning hover:border-warning hover:text-secondary rounded-lg'
          />
          <Button
            label='Cancel'
            onClick={handleCancel}
            className='px-3 py-2 w-full md:w-fit border-2 border-error bg-error/10 text-error text-lg hover:bg-error hover:border-error hover:text-secondary rounded-lg'
          />
          <Button
            label='Save Supplier Details'
            type='submit' // Change to type='submit' for form submission
            className={`px-3 py-2 w-full md:w-fit border-2 border-success bg-success/10 text-success text-lg hover:bg-success hover:text-secondary rounded-lg ${
              supplier.name === '' ? 'cursor-not-allowed' : 'cursor-pointer'
            }`}
            disabled={supplier.name === ''}
          />
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddSupplier;
