import React, { createContext, useState } from 'react';

// Create the context
export const InventoryContext = createContext();

// Create a provider component
export const InventoryProvider = ({ children }) => {
  const [selectedScreen, setSelectedScreen] = useState('');
  const [inventorySelected, setInventorySelected] = useState('');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [itemSelected, setItemSelected] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [itemDetails, setItemDetails] = useState(false);
  const [itemUpdate, setItemUpdate] = useState(false);
  const [addItem, setAddItem] = useState(false);
  const [inventoryList, setInventoryList] = useState();
  const [processingSale, setProcessingSale] = useState(false);
  const [refetch, setRefetch] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleScreenSelection = (screen) => {
    setSelectedScreen(screen);
  };

  const handleInventorySelection = (item) => {
    setInventorySelected(item);
  };

  //Code to handle inventory change
  const handleItemSelection = (item) => {
    setItemSelected(item);
    // setSideBar(!sideBar);

    // navigate(`/pharmacy/inventory/${inventorySelected}/${item.name}`);
  };

  return (
    <InventoryContext.Provider
      value={{
        selectedScreen,
        setSelectedScreen,
        inventorySelected,
        setInventorySelected,
        isMobileMenuOpen,
        setIsMobileMenuOpen,
        toggleMobileMenu,
        handleScreenSelection,
        handleInventorySelection,
        handleItemSelection,
        filteredData,
        setFilteredData,
        itemSelected,
        setItemSelected,
        searchValue,
        setSearchValue,
        searchResult,
        setSearchResult,
        itemDetails,
        setItemDetails,
        itemUpdate,
        setItemUpdate,
        addItem,
        setAddItem,
        inventoryList,
        setInventoryList,
        processingSale,
        setProcessingSale,
        refetch,
        setRefetch,
      }}
    >
      {children}
    </InventoryContext.Provider>
  );
};
