import React, { useEffect, useState } from 'react';
import NewUser from '../../components/NewUser';
import axios from 'axios';
import SearchField from '../../components/SearchField';
import Button from '../../components/Button';
import { OrbitProgress } from 'react-loading-indicators';
import { network_error } from '../../assets';
import UsersList from '../../components/UsersList';

const Users = () => {
  const [selectedValue, setSelectedValue] = useState('all users');
  const [newUserModal, setNewUserModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [refetch, setRefetch] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const token = localStorage.getItem('token');

  // Function to fetch users
  const fetchUsers = async () => {
    setLoading(true);
    setError(null); // Reset error state before fetching
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users`,
        {
          headers: {
            token: `Bearer ${token}`,
          },
        }
      );
      setUsers(response.data);
      setFilteredUsers(response.data);
    } catch (err) {
      console.error('Error fetching users:', err);
      setError('An error occurred while fetching users.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [refetch]);

  // Function to filter users based on search input
  useEffect(() => {
    const lowerCaseValue = searchValue.toLowerCase();

    // Create an array with filtered data
    if (searchValue === '') {
      // Reset filteredUsers to the original users when searchValue is empty
      setFilteredUsers(users);
    } else {
      const filtered = users.filter((user) => {
        const matchesFirstName =
          user?.firstName &&
          user?.firstName.toLowerCase().includes(lowerCaseValue);
        const matchesLastName =
          user?.lastName &&
          user?.lastName.toLowerCase().includes(lowerCaseValue);

        return matchesFirstName || matchesLastName;
      });
      setFilteredUsers(filtered);
    }
  }, [searchValue, users]); // Update dependency to include users

  // Filter users based on the selected value
  useEffect(() => {
    let filtered = users; // Start with the original users array

    // Filter based on the selected value
    if (selectedValue === 'admins') {
      filtered = filtered.filter((user) => user?.isAdmin);
    } else if (selectedValue === 'cashiers') {
      filtered = filtered.filter((user) => user?.isCashier);
    }

    // Update filteredUsers based on the search value
    const lowerCaseValue = searchValue.toLowerCase();
    if (searchValue) {
      filtered = filtered.filter((user) => {
        const matchesFirstName =
          user?.firstName &&
          user?.firstName.toLowerCase().includes(lowerCaseValue);
        const matchesLastName =
          user?.lastName &&
          user?.lastName.toLowerCase().includes(lowerCaseValue);

        return matchesFirstName || matchesLastName;
      });
    }

    setFilteredUsers(filtered);
  }, [selectedValue, searchValue, users]); // Update dependencies

  const createNewUser = () => {
    setNewUserModal(true);
  };

  const closeNewUserModal = () => {
    setNewUserModal(false);
    setEditUser(false);
    setSelectedUser('');
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // Function to handle closing search bar
  const handleCloseSearch = () => {
    setSearchValue('');
  };

  // Render loading state
  if (loading) {
    return (
      <div
        className='flex flex-col items-center justify-center inset-0'
        style={{
          height: 'calc(100vh - 240px)',
        }}
      >
        <OrbitProgress color='#32cd32' size='small' />
        <p className='text-2xl font-thin'>Loading users...</p>
      </div>
    );
  }

  // Render error state
  if (error) {
    return (
      <div
        className='flex flex-col gap-3 items-center justify-center inset-0 text-xl'
        style={{
          height: 'calc(100vh - 240px)',
        }}
      >
        <img src={network_error} alt='network error' className='w-48' />
        <p className='text-center w-[80%]'>{error}</p>
        <button
          onClick={fetchUsers}
          className='cursor-pointer border p-2 rounded-lg bg-accent hover:bg-primary text-white w-20'
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className='flex flex-col mt-5'>
      <div className='flex p-2 w-full items-center justify-between bg-white rounded-lg shadow-md gap-4'>
        <div className='flex-[2] w-full'>
          <SearchField
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            handleCloseSearch={handleCloseSearch}
            placeholder='Search User ...'
          />
        </div>
        <div className='flex-1 flex items-center justify-end gap-4'>
          <div className='border p-1 px-2 rounded-lg'>
            <label htmlFor='dropdown' className='text-gray-700'>
              Showing
            </label>
            <select
              id='dropdown'
              value={selectedValue}
              onChange={handleSelectChange}
              className=' p-1 focus:outline-none'
            >
              <option value='all users'>All Users</option>
              <option value='admins'>Admins</option>
              <option value='cashiers'>Pharmacists</option>
            </select>
          </div>
          <div className='flex items-center justify-center'>
            <Button
              className='p-2 rounded-lg bg-accent hover:bg-primary text-white'
              onClick={createNewUser}
              label='Add User'
            />
          </div>
        </div>
      </div>
      <div
        className='flex flex-wrap justify-between gap-5 overflow-y-auto mt-3 rounded-lg md:rounded-lg w-full'
        style={{
          height: 'calc(100vh - 240px)',
        }}
      >
        <div className='h-fit w-full'>
          <UsersList
            users={filteredUsers}
            refetch={refetch}
            setRefetch={setRefetch}
            setNewUserModal={setNewUserModal}
            setEditUser={setEditUser}
            setSelectedUser={setSelectedUser}
          />
        </div>
      </div>
      <div className='z-40'>
        {newUserModal && (
          <NewUser
            users={filteredUsers}
            editUser={editUser}
            closeNewUserModal={closeNewUserModal}
            refetch={refetch}
            setRefetch={setRefetch}
            selectedUser={selectedUser}
          />
        )}
      </div>
    </div>
  );
};

export default Users;
