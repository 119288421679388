import React, { useContext, useState, useEffect } from 'react';
import { IoTrashOutline } from 'react-icons/io5';
import Button from './Button';
// import { useNavigate } from 'react-router-dom';
import { OrderContext } from '../context/OrderProvider';
import PurchaseOrderPDF from './PurchaseOrderPDF';
import { pdf } from '@react-pdf/renderer';
import { facilityDetails } from '../constants/facility';
import { AuthContext } from '../context/AuthProvider';
import { amg_logo } from '../assets';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import colors from '../assets/colors';

const PurchaseOrders = ({ supplierList }) => {
  const { setPageSelected } = useContext(OrderContext);
  const { user } = useContext(AuthContext);

  const [supplier, setSupplier] = useState('');
  const [items, setItems] = useState([{ name: '', quantity: '', price: '' }]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [purchaseOrder, setPurchaseOrder] = useState(null);
  const [supplierData, setSupplierData] = useState([]);

  const token = localStorage.getItem('token');

  const handleItemChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;

    if (field === 'quantity' || field === 'price') {
      const quantity = Number(newItems[index].quantity);
      const price = Number(newItems[index].price);
      newItems[index].total = quantity * price;
    }

    setItems(newItems);
    calculateTotalAmount(newItems);
  };

  const calculateTotalAmount = (items) => {
    const total = items.reduce((sum, item) => sum + (item.total || 0), 0);
    setTotalAmount(total);
  };

  const addItem = () => {
    setItems([...items, { name: '', quantity: '', price: '' }]);
  };

  const removeItem = (index) => {
    items.length > 1 && setItems(items.filter((item, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const purchaseOrder = {
      supplier,
      items: items.map((item) => ({
        name: item.name,
        quantity: Number(item.quantity),
        price: Number(item.price),
        total: item.total || Number(item.quantity) * Number(item.price),
      })),
      totalAmount,
      status: 'pending',
    };

    // Show a "Saving..." toast notification while data is being processed
    const savingToastId = toast.info(`Processing Purchase Order ...`, {
      position: 'top-center',
      autoClose: false, // Keep it open until dismissed manually
      style: {
        backgroundColor: '#FFD700', // Customize the background color (yellowish for "saving")
        color: '#000',
        textAlign: 'center',
      },
      icon: false,
      hideProgressBar: true,
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/purchase-orders`,
        purchaseOrder,
        {
          headers: {
            token: `Bearer ${token}`,
          },
        }
      );

      const savedOrder = response.data;

      // Dismiss the "Saving..." toast
      toast.dismiss(savingToastId);

      toast.success(
        `Purchase Order for ${savedOrder.supplier} added successfully!`,
        {
          position: 'top-center',
          autoClose: 500,
          // Styling the pop-up message
          style: {
            backgroundColor: `${colors.success}`,
            color: '#fff',
            textAlign: 'center',
          },
          icon: false,
          // Remove the progress bar
          hideProgressBar: true,
        }
      );

      setPurchaseOrder(purchaseOrder);

      setSupplier('');
      setItems([{ name: '', quantity: '', price: '' }]);
      setTotalAmount(0);
    } catch (error) {
      toast.error(`Error while adding purchase order: ${error} `, {
        position: 'top-center',
        icon: false,
        // Remove the progress bar
        hideProgressBar: true,
        // Styling the pop-up message
        style: {
          backgroundColor: `${colors.error}`,
          color: '#fff',
          textAlign: 'center',
        },
      });
      console.error('Error while adding purchase order:', error);
    }
  };

  const handleCancel = () => {
    setItems([{ name: '', quantity: '', price: '' }]);
    setPageSelected('');
  };

  useEffect(() => {
    const generatePDF = async () => {
      if (purchaseOrder) {
        const blob = await pdf(
          <PurchaseOrderPDF
            supplier={purchaseOrder.supplier}
            supplierData={supplierData}
            items={purchaseOrder.items}
            totalAmount={purchaseOrder.totalAmount}
            facilityDetails={facilityDetails}
            createdBy={`${user.firstName} ${user.lastName}`}
            amg_logo={amg_logo}
          />
        ).toBlob();

        const url = URL.createObjectURL(blob);
        window.open(url);
      }
    };

    generatePDF();
  }, [purchaseOrder]);

  useEffect(() => {
    const supplierInfo =
      supplierList && supplierList.filter((sup) => sup.name === supplier);
    setSupplierData(supplierInfo);
  }, [supplier, supplierList]);

  const preventEInput = (e) => {
    if (e.key === 'e' || e.key === 'E' || e.key === '+' || e.key === '-') {
      e.preventDefault();
    }
  };

  return (
    <div
      className='rounded-lg overflow-auto'
      style={{ height: 'calc(100vh - 140px)' }}
    >
      <div className='p-5 rounded-lg shadow-lg bg-white'>
        <div className='w-full mb-4'>
          <select
            id='dropdown'
            name='category'
            value={supplier}
            onChange={(e) => setSupplier(e.target.value)}
            className='h-14 w-full border border-gray-300 px-3 text-base focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent rounded-lg'
            style={{ maxHeight: '200px', overflowY: 'auto' }}
          >
            <option value='' disabled>
              Choose A Supplier
            </option>
            {supplierList &&
              supplierList.map((sup, index) => (
                <option key={index} value={sup.name} className='capitalize'>
                  {sup.name}
                </option>
              ))}
          </select>
        </div>

        <div className='flex items-center justify-center text-xl my-4'>
          <h3 className='w-fit text-2xl px-4 text-primary border-b-2 border-primary'>
            Items
          </h3>
        </div>
        {items.map((item, index) => (
          <div key={index} className='flex flex-wrap items-center gap-2 my-2'>
            <input
              type='text'
              placeholder='Item Name'
              value={item.name}
              onChange={(e) => handleItemChange(index, 'name', e.target.value)}
              className='border border-gray-300 rounded-lg p-2 flex-1'
              required
            />
            <input
              type='number'
              placeholder='Quantity'
              value={item.quantity}
              onKeyDown={preventEInput} // Prevents 'e', 'E', '+', '-'
              onChange={(e) =>
                handleItemChange(index, 'quantity', e.target.value)
              }
              className='border border-gray-300 rounded-lg p-2 w-24'
              required
              min='1'
            />
            <input
              type='number'
              placeholder='Price'
              value={item.price}
              onKeyDown={preventEInput} // Prevents 'e', 'E', '+', '-'
              onChange={(e) => handleItemChange(index, 'price', e.target.value)}
              className='border border-gray-300 rounded-lg p-2 w-32'
              required
              min='0'
            />
            <p className='flex items-center justify-end min-w-[150px]'>
              Total: {new Intl.NumberFormat('en-US').format(item.total || 0)}{' '}
              UGX
            </p>
            <div
              onClick={() => removeItem(index)}
              className='flex items-center justify-center border-2 border-error p-2 rounded-full text-error hover:text-white hover:bg-error'
            >
              <IoTrashOutline />
            </div>
            <div className='my-1 h-[2px] bg-black/10 border w-[99%] m-auto rounded-full'></div>
          </div>
        ))}
        <button
          type='button'
          onClick={addItem}
          className='bg-blue-500 text-white px-4 py-2 rounded-lg mb-4'
        >
          Add Item
        </button>
        <div className='mb-4 font-urbanist text-2xl'>
          Total Amount: {new Intl.NumberFormat('en-US').format(totalAmount)} UGX
        </div>
        <div className='flex flex-col md:flex-row items-center gap-5'>
          <Button
            label='Clear Order'
            onClick={() => setItems([{ name: '', quantity: '', price: '' }])}
            className='px-3 py-2 w-full md:w-fit border-2 border-warning bg-warning/10 text-warning text-lg hover:bg-warning hover:border-warning hover:text-secondary rounded-lg'
          />
          <Button
            label='Cancel Order'
            onClick={handleCancel}
            className='px-3 py-2 w-full md:w-fit border-2 border-error bg-error/10 text-error text-lg hover:bg-error hover:border-error hover:text-secondary rounded-lg'
          />
          <Button
            label='Generate Purchase Order'
            onClick={handleSubmit}
            className={`px-3 py-2 w-full md:w-fit border-2 border-success bg-success/10 text-success text-lg hover:bg-success hover:text-secondary rounded-lg ${
              supplier === '' ? 'cursor-not-allowed' : 'cursor-pointer'
            }`}
            disabled={supplier === ''}
          />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PurchaseOrders;
