import React, { useContext, useEffect, useRef, useState } from 'react';
import SearchField from './SearchField';
import ListOfOrders from './ListOfOrders';
import { IoCheckmarkCircleOutline, IoClose } from 'react-icons/io5';
import Button from './Button';
import { MdCancel, MdOutlinePending } from 'react-icons/md';
import { OrderContext } from '../context/OrderProvider';
import axios from 'axios';

const OrdersList = ({ ordersList }) => {
  const { orderStatusChange, setOrderStatusChange } = useContext(OrderContext);

  const [searchValue, setSearchValue] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [orderSelected, setOrderSelected] = useState('');
  const [filter, setFilter] = useState('all orders');
  const [colour, setColour] = useState('');
  const [icon, setIcon] = useState('');

  const detailsRef = useRef(null);

  const token = localStorage.getItem('token');

  // UseEffect to set the list of orders to filteredData
  useEffect(() => {
    setFilteredData(ordersList); // Set filteredData to ordersList
    setSearchResult(ordersList); // Initialize searchResult with ordersList
  }, [orderStatusChange, ordersList]); // Depend on ordersList

  // Function to filter orders based on search input
  useEffect(() => {
    const lowerCaseValue = searchValue.toLowerCase();

    // Create an array with filtered data
    const filtered = filteredData.filter((item) => {
      const matchesSupplierName =
        item?.supplierName &&
        item?.supplierName.toLowerCase().includes(lowerCaseValue);

      const matchesPONo = item?.id && String(item.id).includes(lowerCaseValue);

      return matchesSupplierName || matchesPONo;
    });
    setSearchResult(filtered);
  }, [searchValue, filteredData]);

  // FIlter orders based on the filter value selected
  useEffect(() => {
    // Create an array with filtered data
    const filtered = filteredData.filter((item) => {
      const matchesFilter =
        item?.status && item?.status.toLowerCase() === filter;

      return matchesFilter;
    });
    if (filter === 'all orders') {
      setSearchResult(filteredData);
    } else {
      setSearchResult(filtered);
    }
  }, [filter, filteredData]);

  // Function to get the status icon
  const getStatusIcon = (status) => {
    switch (status) {
      case 'delivered':
        return <IoCheckmarkCircleOutline />;
      case 'pending':
        return <MdOutlinePending />;
      case 'cancelled':
        return <MdCancel />;
      default:
        return null; // Return null or a default icon if needed
    }
  };

  // Function to get the status colour
  const getStatusColor = (status) => {
    switch (status) {
      case 'delivered':
        return 'bg-success/80';
      case 'pending':
        return 'bg-warning/80';
      case 'cancelled':
        return 'bg-error/80';
      default:
        return null; // Return null or a default icon if needed
    }
  };

  // Effect to fetch colours and icon when status changes
  useEffect(() => {
    setColour(getStatusColor(orderSelected.status));
    setIcon(getStatusIcon(orderSelected.status));
  }, [orderStatusChange, orderSelected]);

  // Function to handle closing search bar
  const handleCloseSearch = () => {
    setSearchValue('');
  };

  // Code to handle order change
  const handleOrderSelection = (item) => {
    setOrderSelected(item);
    // setSideBar(!sideBar);
    // navigate(`/pharmacy/inventory/${inventorySelected}/${item.name}`);
  };

  // Function to handle clicks outside the details section
  const handleClickOutside = (event) => {
    if (detailsRef.current && !detailsRef.current.contains(event.target)) {
      setOrderSelected('');
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Function to handle order cancellation and order delivered (order status)
  const handleStatusChange = async (status) => {
    try {
      // Find the order in the orders list
      const orderToUpdate =
        ordersList && ordersList.find((ord) => ord._id === orderSelected._id);

      if (orderToUpdate) {
        // Update the status of the order in the backend
        await axios.put(
          `${process.env.REACT_APP_API_URL}/purchase-orders/${orderToUpdate._id}`,
          { status: status },
          {
            headers: {
              token: `Bearer ${token}`,
            },
          }
        );

        // Update the selected order to reflect the new status
        setOrderSelected((prevOrder) => ({
          ...prevOrder,
          status: status, // Update the status in the selected order
        }));

        alert('Order status updated successfully');
        setOrderStatusChange(!orderStatusChange);
      } else {
        console.error('Order not found:', orderSelected._id);
      }
    } catch (error) {
      console.error('Error updating order status:', error.message);
    }
  };

  return (
    <div
      ref={detailsRef}
      className='flex flex-col lg:flex-row gap-3 w-full h-full'
    >
      <div
        className='flex-[3] flex flex-col gap-3'
        style={{ height: 'calc(100vh - 140px)' }}
      >
        {(!orderSelected || window.innerWidth >= 1024) && (
          <>
            <div className='flex items-center gap-5 bg-white p-2 rounded-lg shadow-lg'>
              <div className='flex-[4]'>
                <SearchField
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  handleCloseSearch={handleCloseSearch}
                  placeholder='Search P.O No. or Supplier ...'
                />
              </div>
              <div className='flex-1'>
                <select
                  id='dropdown'
                  name='category'
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  className='w-full border border-gray-300 p-1 text-base focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent rounded-lg'
                >
                  <option value='all orders'>All orders</option>
                  <option value='pending'>Pending Orders</option>
                  <option value='cancelled'>Cancelled Orders</option>
                  <option value='delivered'>Delivered Orders</option>
                </select>
              </div>
            </div>
            <ListOfOrders
              searchResult={searchResult}
              handleOrderSelection={handleOrderSelection}
            />
          </>
        )}
      </div>
      {orderSelected && (
        <div className='flex-1 flex flex-col items-center justify-center text-sm p-2 gap-2 bg-accent rounded-lg shadow-lg h-fit sticky top-0'>
          <div className='flex flex-col p-2 bg-white w-full rounded-md'>
            <div className='flex items-center justify-between'>
              <div className='w-fit'>
                <div className='max-w-[200px] text-primary truncate font-urbanist text-xl'>
                  {orderSelected.supplier}
                </div>
                <div className='h-[2px] bg-black/50 border w-[80%] rounded-full'></div>
              </div>
              <div className='flex items-center justify-end '>
                <div
                  className='cursor-pointer text-red-600 hover:bg-red-600 hover:text-white p-[2px] rounded-md border border-red-600 text-xl'
                  onClick={() => {
                    setOrderSelected('');
                  }}
                >
                  <IoClose />
                </div>
              </div>
            </div>
            <div className='flex flex-col gap-2 mt-2'>
              <div className='flex'>
                <p className='flex-1'>P.O No. </p>
                <p className='flex-1 font-semibold'>{orderSelected.id}</p>
              </div>
              <div className='flex'>
                <p className='flex-1'>Quantity </p>
                <p className='flex-1 font-semibold'>
                  {orderSelected.items.map((item) => item).length}
                </p>
              </div>
              <div className='flex'>
                <p className='flex-1'>Items </p>
                <p className='flex-1 font-semibold'>
                  {orderSelected.items.map((item) => item.name).join(', ')}
                </p>
              </div>
              <div className='flex'>
                <p className='flex-1'>Amount </p>
                <p className='flex-1 font-semibold'>
                  {new Intl.NumberFormat('en-US').format(
                    orderSelected.totalAmount
                  )}{' '}
                  UGX
                </p>
              </div>
              <div className='flex'>
                <p className='flex-1'>Order Date </p>
                <p className='flex-1 font-semibold'>
                  {new Date(orderSelected.createdAt).toLocaleDateString(
                    'en-US',
                    {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    }
                  )}
                </p>
              </div>
              <div className='flex'>
                <p className='flex-1'>Status </p>
                <p
                  className={`flex-1 flex items-center justify-center text-white gap-2 rounded-lg py-2 w-full ${colour}`}
                >
                  {icon}
                  {orderSelected.status}
                </p>
              </div>
            </div>
            <div className='h-[2px] bg-black/50 border w-full my-2 rounded-full'></div>
            <div className='flex items-center justify-center gap-1'>
              <div className='flex-1'>
                <Button
                  label='Cancel Order'
                  className='font-urbanist px-2 py-1 w-full border-2 border-error bg-error/10 text-error hover:bg-error hover:text-white rounded-md'
                  onClick={() => handleStatusChange('cancelled')}
                />
              </div>
              <div className='flex-1'>
                <Button
                  label='Delivered'
                  className='font-urbanist px-2 py-1 w-full border-2 border-success bg-success/10 text-success hover:bg-success hover:text-white rounded-md'
                  onClick={() => handleStatusChange('delivered')}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrdersList;
