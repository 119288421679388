import React, { useContext, useEffect, useState } from 'react';
import { IoCartOutline, IoHomeOutline } from 'react-icons/io5';
import { MdOutlinePointOfSale } from 'react-icons/md';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { IoPeopleOutline } from 'react-icons/io5';
import { MdOutlineInventory } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import { inventory } from '../constants/inventory';
import { OrderContext } from '../context/OrderProvider';
import { AuthContext } from '../context/AuthProvider';

const PharmaSideBar = ({
  isOpen,
  selectedScreen,
  handleScreenSelection,
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  toggleMobileMenu,
  inventorySelected,
  setInventorySelected,
  handleInventorySelection,
}) => {
  const { user } = useContext(AuthContext);
  const { setPageSelected } = useContext(OrderContext);

  const [isInventoryOpen, setIsInventoryOpen] = useState(false);

  useEffect(() => {
    if (selectedScreen === 'inventory') {
      setIsInventoryOpen(true);
    } else {
      setIsInventoryOpen(false);
    }
  }, [selectedScreen]);

  const handleClick = (screen) => {
    handleScreenSelection(screen);
    setIsInventoryOpen(false);
    setIsMobileMenuOpen(false);
    setInventorySelected('');
    setPageSelected('');
  };

  return (
    <div className='flex flex-col justify-between h-full text-gray-500'>
      <div className='hidden lg:flex flex-col gap-3 py-2 px-2 mx-0 font-light'>
        <Link to='/pharmacy/dashboard'>
          <div
            className={`flex gap-2 items-center justify-start cursor-pointer px-2  py-1 hover:bg-accent hover:text-white rounded-md ${
              selectedScreen === 'dashboard' && 'text-white bg-primary'
            }`}
            onClick={() => handleClick('dashboard')}
          >
            <div className='text-xl md:text-lg p-1'>
              <IoHomeOutline />
            </div>
            <div className={`${isOpen ? 'block' : 'hidden'}`}>Dashboard</div>
          </div>
        </Link>
        {user.isCashier && (
          <Link to='/pharmacy/pos'>
            <div
              className={`flex gap-2 items-center justify-start cursor-pointer px-2  py-1 hover:bg-accent hover:text-white rounded-md ${
                selectedScreen === 'pos' && 'text-white bg-primary'
              }`}
              onClick={() => handleClick('pos')}
            >
              <div className='text-xl md:text-lg p-1'>
                <MdOutlinePointOfSale />
              </div>
              <div className={`${isOpen ? 'block' : 'hidden'}`}>POS</div>
            </div>
          </Link>
        )}
        <div>
          <Link to='/pharmacy/inventory'>
            <div
              className={`flex gap-2 items-center justify-start cursor-pointer px-2  py-1 hover:bg-accent hover:text-white rounded-md ${
                selectedScreen === 'inventory' && 'text-white bg-primary'
              }`}
              onClick={() => handleClick('inventory')}
            >
              <div className='text-xl md:text-lg p-1'>
                <MdOutlineInventory />
              </div>
              <div className={`${isOpen ? 'block' : 'hidden'}`}>Inventory</div>
            </div>
          </Link>
          {isInventoryOpen && isOpen && (
            <div className='flex items-end w-full'>
              <div className='flex flex-col gap-1 p-1 mt-1 bg-background/50 text-sm rounded-lg max-h-40 overflow-y-auto'>
                {inventory.map((item, index) => (
                  <Link to={`/pharmacy/inventory/${item}`} key={index}>
                    <p
                      className={`truncate max-w-[150px] p-1 hover:bg-accent/50 hover:text-white rounded-md cursor-pointer ${
                        inventorySelected === item && 'text-white bg-accent'
                      }`}
                      onClick={() => {
                        handleInventorySelection(item);
                      }}
                    >
                      {item}
                    </p>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
        {/* Orders to be returned when we have other departments sch as the doctor, lab etc */}
        <Link to='/pharmacy/orders'>
          <div
            className={`flex gap-2 items-center justify-start cursor-pointer px-2  py-1 hover:bg-accent hover:text-white rounded-md ${
              selectedScreen === 'orders' && 'text-white bg-primary'
            }`}
            onClick={() => handleClick('orders')}
          >
            <div className='text-xl md:text-lg p-1'>
              <IoCartOutline />
            </div>
            <div className={`${isOpen ? 'block' : 'hidden'}`}>Orders</div>
          </div>
        </Link>
        {/* <Link to='/pharmacy/purchaseorders'>
          <div
            className={`flex gap-2 items-center justify-start cursor-pointer px-2  py-1 hover:bg-accent hover:text-white rounded-md ${
              selectedScreen === 'purchaseorders' && 'text-white bg-primary'
            }`}
            onClick={() => {
              handleScreenSelection('purchaseorders');
              setIsInventoryOpen(false);
              setInventorySelected('');
            }}
          >
            <div className='text-xl md:text-lg p-1'>
              <IoReceiptOutline />
            </div>
            <div className={`${isOpen ? 'block' : 'hidden'}`}>
              Purchase Orders
            </div>
          </div>
        </Link> */}
        <Link to='/pharmacy/reports'>
          <div
            className={`flex gap-2 items-center justify-start cursor-pointer px-2  py-1 hover:bg-accent hover:text-white rounded-md ${
              selectedScreen === 'reports' && 'text-white bg-primary'
            }`}
            onClick={() => handleClick('reports')}
          >
            <div className='text-xl md:text-lg p-1'>
              <HiOutlineDocumentReport />
            </div>
            <div className={`${isOpen ? 'block' : 'hidden'}`}>Reports</div>
          </div>
        </Link>
        {user.isAdmin && (
          <Link to='/pharmacy/users'>
            <div
              className={`flex gap-2 items-center justify-start cursor-pointer px-2  py-1 hover:bg-accent hover:text-white rounded-md ${
                selectedScreen === 'users' && 'text-white bg-primary'
              }`}
              onClick={() => handleClick('users')}
            >
              <div className='text-xl md:text-lg p-1'>
                <IoPeopleOutline />
              </div>
              <div className={`${isOpen ? 'block' : 'hidden'}`}>Users</div>
            </div>
          </Link>
        )}
      </div>

      {/* Mobile Menu Overlay */}
      {isMobileMenuOpen && (
        <div className='fixed inset-0 bg-black bg-opacity-75 flex flex-col items-center justify-center py-5'>
          <div className='flex flex-[10] flex-col items-center justify-center w-[40%]'>
            <div className='flex flex-col items-center text-white text-2xl mb-4'>
              <h3>Menu</h3>
              <div
                className={`m-auto w-full h-[2px] rounded-full bg-white`}
              ></div>
            </div>
            <div className='flex flex-col items-center w-full'>
              <Link to='/pharmacy/dashboard' className='w-full'>
                <div
                  className='flex items-center justify-center gap-2 text-white text-lg py-2 capitalize rounded-lg hover:bg-white hover:text-black w-full'
                  onClick={() => handleClick('dashboard')}
                >
                  <div>Dashboard</div>
                </div>
              </Link>
              <Link to='/pharmacy/pos' className='w-full'>
                <div
                  className='flex items-center justify-center gap-2 text-white text-lg py-2 capitalize rounded-lg hover:bg-white hover:text-black w-full'
                  onClick={() => handleClick('pos')}
                >
                  <div>POS</div>
                </div>
              </Link>
              <Link to='/pharmacy/inventory' className='w-full'>
                <div
                  className='flex items-center justify-center gap-2 text-white text-lg py-2 capitalize rounded-lg hover:bg-white hover:text-black w-full'
                  onClick={() => handleClick('inventory')}
                >
                  <div>Inventory</div>
                </div>
              </Link>
              <Link to='/pharmacy/orders' className='w-full'>
                <div
                  className='flex items-center justify-center gap-2 text-white text-lg py-2 capitalize rounded-lg hover:bg-white hover:text-black w-full'
                  onClick={() => handleClick('orders')}
                >
                  <div>Orders</div>
                </div>
              </Link>
              {/* <Link to='/pharmacy/purchaseorders' className='w-full'>
                <div
                  className='flex items-center justify-center gap-2 text-white text-lg py-2 capitalize rounded-lg hover:bg-white hover:text-black w-full'
                  onClick={() => {
                    handleScreenSelection('orders');
                    setIsMobileMenuOpen(false);
                    setInventorySelected('');
                  }}
                >
                  <div>Purchase Orders</div>
                </div>
              </Link> */}
              <Link to='/pharmacy/reports' className='w-full'>
                <div
                  className='flex items-center justify-center gap-2 text-white text-lg py-2 capitalize rounded-lg hover:bg-white hover:text-black w-full'
                  onClick={() => handleClick('reports')}
                >
                  <div>Reports</div>
                </div>
              </Link>
              {user.isAdmin && (
                <Link to='/pharmacy/users' className='w-full'>
                  <div
                    className='flex items-center justify-center gap-2 text-white text-lg py-2 capitalize rounded-lg hover:bg-white hover:text-black w-full'
                    onClick={() => handleClick('users')}
                  >
                    <div>Users</div>
                  </div>
                </Link>
              )}
              {/* <Link
                to='/settings'
                className='flex items-center justify-center text-lg py-2 mt-10 capitalize rounded-lg bg-white/80 hover:bg-white  text-black w-full'
                onClick={() => handleClick('settings')}
              >
                Settings
              </Link> */}
            </div>
          </div>
          <div
            className='flex items-center justify-center flex-1 lg:hidden '
            onClick={toggleMobileMenu}
          >
            <button className='border-2 border-white p-4 rounded-full text-white hover:bg-white hover:text-black'>
              {isMobileMenuOpen && <FaTimes size={24} />}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PharmaSideBar;
