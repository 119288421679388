import React, { useContext } from 'react';
import { InventoryContext } from '../context/InventoryProvider';

const ItemDetails = () => {
  const { itemSelected } = useContext(InventoryContext);

  return (
    <div className='bg-white rounded-lg shadow-lg p-6'>
      <h2 className='text-5xl font-urbanist font-light mb-2 text-center text-primary'>
        {itemSelected.name}
      </h2>
      {itemSelected.genericName && (
        <p className='text-sm text-gray-600 text-center'>
          Generic Name:{' '}
          <span className='font-semibold'>{itemSelected.genericName}</span>
        </p>
      )}
      <p className='text-sm text-gray-600 text-center'>
        Category: <span className='font-semibold'>{itemSelected.category}</span>
      </p>
      <div className='flex flex-col gap-2 mt-4'>
        <div className='flex justify-between'>
          <span className='text-gray-600'> Current Stock:</span>
          <span className='font-semibold text-gray-800'>
            {new Intl.NumberFormat('en-US').format(itemSelected.stock)}
          </span>
        </div>
        <div className='flex justify-between'>
          <span className='text-gray-600'>Stock Last Month:</span>
          <span className='font-semibold text-gray-800'>
            {new Intl.NumberFormat('en-US').format(itemSelected.lastMonthStock)}
          </span>
        </div>
        <div className='flex justify-between'>
          <span className='text-gray-600'>Stock Added:</span>
          <span className='font-semibold text-gray-800'>
            {new Intl.NumberFormat('en-US').format(itemSelected.stockAdded)}
          </span>
        </div>
        <div className='flex justify-between'>
          <span className='text-gray-600'>Date of Expiry:</span>
          <span className='font-semibold text-gray-800'>
            {itemSelected.dateOfExpiry
              ? new Date(itemSelected.dateOfExpiry).toLocaleDateString(
                  'en-US',
                  {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  }
                )
              : 'N/A'}
          </span>
        </div>
        <div className='flex justify-between'>
          <span className='text-gray-600'>Manufacturer:</span>
          <span className='font-semibold text-gray-800'>
            {itemSelected.manufacturer}
          </span>
        </div>
        <div className='flex justify-between'>
          <span className='text-gray-600'>Batch Number:</span>
          <span className='font-semibold text-gray-800'>
            {itemSelected.batchNumber}
          </span>
        </div>
        <div className='flex justify-between'>
          <span className='text-gray-600'>Unit Price:</span>
          <span className='font-semibold text-gray-800'>
            {new Intl.NumberFormat('en-US').format(itemSelected.unitPrice)} UGX
          </span>
        </div>
        <div className='flex justify-between'>
          <span className='text-gray-600'>Storage Condition:</span>
          <span className='font-semibold text-gray-800'>
            {itemSelected.storageCondition}
          </span>
        </div>
      </div>
      {itemSelected.sideEffects && (
        <div className='mt-4'>
          <h3 className='text-lg font-semibold text-gray-800'>Side Effects</h3>
          <p className='text-gray-600'>{itemSelected.sideEffects}</p>
        </div>
      )}
      {itemSelected.description && (
        <div className='mt-4'>
          <h3 className='text-lg font-semibold text-gray-800'>Description</h3>
          <p className='text-gray-600'>{itemSelected.description}</p>
        </div>
      )}
      <div className='mt-4'>
        <h3 className='text-lg font-semibold text-gray-800'>
          Country of Origin
        </h3>
        <p className='text-gray-600'>{itemSelected.countryOfOrigin}</p>
      </div>
    </div>
  );
};

export default ItemDetails;
