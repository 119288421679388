import React, { useContext, useState } from 'react';
import Button from './Button';
import axios from 'axios';
import Modal from './SupplierModal';
import InputField from './InputField';
import { OrderContext } from '../context/OrderProvider';

const SupplierList = ({ handleSupplierSelection, searchResult }) => {
  const { refetch, setRefetch } = useContext(OrderContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  const token = localStorage.getItem('token');

  const handleEdit = (supplier) => {
    setSelectedSupplier(supplier);
    setIsModalOpen(true);
  };

  // FUnction to handle delete of supplier
  const handleDelete = async (supplier) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this supplier?'
    );

    if (confirmDelete) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/suppliers/${supplier._id}`,
          {
            headers: {
              token: `Bearer ${token}`,
            },
          }
        );

        if (!response.status === 200)
          console.error('Error deleting supplier:', response.statusText);

        setRefetch(!refetch);
      } catch (error) {
        console.error(
          'Error deleting supplier:',
          error.response ? error.response.data : error.message
        );
      }
    } else {
      console.log('Delete action canceled');
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedSupplier(null);
  };

  const handleSave = async (updatedSupplier) => {
    if (!updatedSupplier) {
      handleCloseModal(); // Close modal if null is passed (on cancel)
      return;
    }

    try {
      // Call your update API here
      await axios.put(
        `${process.env.REACT_APP_API_URL}/suppliers/${updatedSupplier._id}`,
        updatedSupplier,
        {
          headers: {
            token: `Bearer ${token}`,
          },
        }
      );

      // Show success alert with the updated supplier's name
      alert(`${updatedSupplier.name} has been updated successfully`);
      setRefetch(!refetch);

      // Refresh or update your suppliers list as needed
      console.log('Supplier updated');
      handleCloseModal();
    } catch (error) {
      console.error('Error updating supplier', error);
    }
  };

  return (
    <div
      className={`h-fit p-2 rounded-lg overflow-x-auto ${
        searchResult.length > 0 ? 'bg-white shadow-lg' : ''
      }`}
    >
      {searchResult.length > 0 ? (
        <table className='table-auto w-full p-2'>
          <thead className='sticky top-0 w-full text-white'>
            <tr className='text-left'>
              <th className='px-2 py-3 text-center rounded-l-lg bg-primary'>
                No.
              </th>
              <th className='px-2 py-3 bg-primary'>Name</th>
              <th className='px-2 py-3 bg-primary truncate max-w-[80px]'>
                Location
              </th>
              <th className='px-2 py-3 bg-primary truncate max-w-[80px]'>
                Email
              </th>
              <th className='px-2 py-3 bg-primary truncate max-w-[80px]'>
                Telephone
              </th>
              <th className='px-2 py-3 text-center bg-primary rounded-r-lg truncate max-w-[80px]'>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {searchResult.map((item, index) => (
              <tr
                key={index}
                onClick={() => handleSupplierSelection(item)}
                className={`hover:bg-gray-200 cursor-pointer ${
                  (index + 1) % 2 === 0 ? 'bg-gray-100' : 'bg-white'
                }`}
              >
                <td className='p-2 rounded-l-lg text-center'>{index + 1}</td>
                <td className='p-2'>{item.name}</td>
                <td className='p-2'>{item.city}</td>
                <td className='p-2'>{item.email}</td>
                <td className='p-2'>{item.phone}</td>
                <td className='p-2 flex flex-col sm:flex-row gap-2 items-center justify-center rounded-r-lg'>
                  <Button
                    label='Edit'
                    className='p-2 border rounded-lg w-full bg-green-50/50 border-green-600 text-green-600 hover:bg-green-600 hover:text-white'
                    onClick={() => handleEdit(item)}
                  />
                  <Button
                    label='Delete'
                    className='p-2 border rounded-lg w-full bg-red-50 border-red-600 text-red-600 hover:bg-red-600 hover:text-white'
                    onClick={() => handleDelete(item)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className='flex items-center justify-center h-40 text-2xl'>
          <p className='capitalize'>No suppliers available</p>
        </div>
      )}

      {/* Modal for Editing Supplier */}
      {isModalOpen && (
        <Modal onClose={handleCloseModal}>
          <SupplierEditForm supplier={selectedSupplier} onSave={handleSave} />
        </Modal>
      )}
    </div>
  );
};

const SupplierEditForm = ({ supplier, onSave }) => {
  const [formData, setFormData] = useState(supplier);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData); // Save the updated supplier details
  };

  // Clear the form to original supplier data
  const handleClearForm = (e) => {
    e.preventDefault(); // Prevent the default button behavior
    setFormData(supplier); // Reset to original supplier data
  };

  // Cancel editing, invoke the onSave with null to close the modal
  const handleCancel = (e) => {
    e.preventDefault(); // Prevent the default button behavior
    onSave(null); // Close modal without saving
  };

  return (
    <form onSubmit={handleSubmit}>
      <p className='my-2 text-center text-gray-800 text-2xl'>
        Update Supplier Details
      </p>
      <div className='mb-4'>
        <InputField
          label='Supplier Name'
          type='text'
          name='name'
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div className='mb-4'>
        <InputField
          label='Contact Person'
          type='text'
          name='contactPerson'
          value={formData.contactPerson}
          onChange={handleChange}
          required
        />
      </div>
      <div className='flex flex-col md:flex-row gap-4 mb-4'>
        <InputField
          label='Phone'
          type='tel'
          name='phone'
          value={formData.phone}
          onChange={handleChange}
          required
        />
        <InputField
          label='Email'
          type='email'
          name='email'
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className='flex flex-col md:flex-row gap-4 mb-4'>
        <InputField
          label='Address'
          type='text'
          name='address'
          value={formData.address}
          onChange={handleChange}
        />
        <InputField
          label='City'
          type='text'
          name='city'
          value={formData.city}
          onChange={handleChange}
        />
        <InputField
          label='Country'
          type='text'
          name='country'
          value={formData.country}
          onChange={handleChange}
        />
      </div>
      <div className='flex flex-col md:flex-row items-center justify-center gap-5'>
        <Button
          label='Clear Form'
          onClick={handleClearForm} // Reset to original supplier data
          className='px-3 py-2 w-full md:w-fit border-2 border-warning bg-warning/10 text-warning text-lg hover:bg-warning hover:border-warning hover:text-secondary rounded-lg'
        />
        <Button
          label='Close'
          onClick={handleCancel} // Close modal without saving
          className='px-3 py-2 w-full md:w-fit border-2 border-error bg-error/10 text-error text-lg hover:bg-error hover:border-error hover:text-secondary rounded-lg'
        />
        <Button
          label='Update Supplier Details'
          type='submit'
          className={`px-3 py-2 w-full md:w-fit border-2 border-success bg-success/10 text-success text-lg hover:bg-success hover:text-secondary rounded-lg ${
            formData.name === '' ? 'cursor-not-allowed' : 'cursor-pointer'
          }`}
          disabled={formData.name === ''} // Disable if name is empty
        />
      </div>
    </form>
  );
};

export default SupplierList;
