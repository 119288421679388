import React, { useState, useContext, useEffect } from 'react';
import { InventoryContext } from '../context/InventoryProvider';
import InputField from './InputField';
import { inventory } from '../constants/inventory';
import Button from './Button';
// import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import colors from '../assets/colors';
import api, { setupInterceptors } from '../api/api';
import { useNavigate } from 'react-router-dom';

const AddItem = () => {
  const { setAddItem, setItemUpdate, itemSelected, refetch, setRefetch } =
    useContext(InventoryContext);

  const [saving, setSaving] = useState(false);

  const token = localStorage.getItem('token');

  const [item, setItem] = useState({
    name: '',
    genericName: '',
    stockAdded: '',
    lastMonthStock: '',
    stock: '',
    category: '',
    dateOfExpiry: '',
    manufacturer: '',
    batchNumber: '',
    unitPrice: '',
    storageCondition: '',
    sideEffects: '',
    description: '',
    countryOfOrigin: '',
  });

  const navigate = useNavigate();

  useEffect(() => {
    // Call setupInterceptors and pass the navigate function
    setupInterceptors(navigate);
  }, [navigate]);

  // Effect to populate the form when itemSelected changes
  useEffect(() => {
    if (itemSelected) {
      setItem({
        name: itemSelected.name,
        genericName: itemSelected.genericName,
        stockAdded: itemSelected.stockAdded,
        lastMonthStock: itemSelected.lastMonthStock,
        stock: itemSelected.stock,
        category: itemSelected.category,
        dateOfExpiry: new Date(itemSelected.dateOfExpiry)
          .toISOString()
          .split('T')[0],
        // dateOfExpiry: itemSelected.dateOfExpiry,
        manufacturer: itemSelected.manufacturer,
        batchNumber: itemSelected.batchNumber,
        unitPrice: itemSelected.unitPrice,
        storageCondition: itemSelected.storageCondition,
        sideEffects: itemSelected.sideEffects,
        description: itemSelected.description,
        countryOfOrigin: itemSelected.countryOfOrigin,
      });
    }
  }, [itemSelected]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItem({ ...item, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);

    const newItem = {
      name: item.name,
      genericName: item.genericName,
      stockAdded: item.stockAdded,
      lastMonthStock: itemSelected ? item.lastMonthStock : 0,
      stock: itemSelected ? item.stock : item.stockAdded,
      category: item.category,
      dateOfExpiry: item.dateOfExpiry,
      manufacturer: item.manufacturer,
      batchNumber: item.batchNumber,
      unitPrice: item.unitPrice,
      storageCondition: item.storageCondition,
      sideEffects: item.sideEffects,
      description: item.description,
      countryOfOrigin: item.countryOfOrigin,
    };

    // Show a "Saving..." toast notification while data is being processed
    const savingToastId = toast.info(
      `Saving ${newItem.name.toUpperCase()} ...`,
      {
        position: 'top-center',
        autoClose: false, // Keep it open until dismissed manually
        style: {
          backgroundColor: '#FFD700', // Customize the background color (yellowish for "saving")
          color: '#000',
          textAlign: 'center',
        },
        icon: false,
        hideProgressBar: true,
      }
    );

    try {
      const response = itemSelected
        ? await api.put(
            `${process.env.REACT_APP_API_URL}/items/${itemSelected._id}`,
            newItem,
            {
              headers: {
                token: `Bearer ${token}`,
              },
            }
          )
        : await api.post(`${process.env.REACT_APP_API_URL}/items`, newItem, {
            headers: {
              token: `Bearer ${token}`,
            },
          });

      // Dismiss the "Saving..." toast
      toast.dismiss(savingToastId);

      const savedItem = response.data;
      toast.success(
        `${savedItem.name} ${itemSelected ? 'updated' : 'added'} successfully!`,
        {
          position: 'top-center',
          autoClose: 500,
          // Styling the pop-up message
          style: {
            backgroundColor: `${colors.success}`,
            color: '#fff',
            textAlign: 'center',
          },
          icon: false,
          // Remove the progress bar
          hideProgressBar: true,
        }
      );

      // Reset form
      handleClear();
      setSaving(false);
      itemSelected && handleCancel();
      setRefetch(!refetch);
    } catch (error) {
      // Dismiss the "Saving..." toast
      toast.dismiss(savingToastId);
      // Handle error response
      setSaving(false);
      if (error.response) {
        toast.error(`Failed to save item: ${error.response.data.message} `, {
          position: 'top-center',
          icon: false,
          // Remove the progress bar
          hideProgressBar: true,
          // Styling the pop-up message
          style: {
            backgroundColor: `${colors.error}`,
            color: '#fff',
            textAlign: 'center',
          },
        });
        console.error('Failed to save item:', error.response.data.message);
      } else {
        toast.error(`Error while saving item: ${error.message}`, {
          position: 'top-center',
          icon: false,
          // Remove the progress bar
          hideProgressBar: true,
          // Styling the pop-up message
          style: {
            backgroundColor: `${colors.error}`,
            color: '#fff',
            textAlign: 'center',
          },
        });
        console.error('Error while saving item:', error.message);
      }
    }
  };

  const handleClear = () => {
    setItem({
      id: '',
      name: '',
      genericName: '',
      stockAdded: '',
      lastMonthStock: '',
      stock: '',
      category: '',
      dateOfExpiry: '',
      manufacturer: '',
      batchNumber: '',
      unitPrice: '',
      storageCondition: '',
      sideEffects: '',
      description: '',
      countryOfOrigin: '',
    });
  };

  const handleCancel = () => {
    setItemUpdate(false);
    setAddItem(false);
    handleClear();
  };

  return (
    <div
      className='overflow-auto rounded-lg'
      style={{ height: 'calc(100vh - 140px)' }}
    >
      <div className='p-4 bg-white shadow-md rounded-lg'>
        <p className='flex items-center justify-center mb-5 text-2xl text-primary font-light'>
          Enter Item Details
        </p>
        <form onSubmit={handleSubmit}>
          <div className='mb-4'>
            <div className='flex flex-col md:flex-row gap-4'>
              <InputField
                label='Name'
                type='text'
                name='name'
                value={item.name}
                onChange={handleChange}
              />
              <InputField
                label='Generic Name'
                type='text'
                name='genericName'
                value={item.genericName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='flex flex-col md:flex-row items-center justify-between gap-4 mb-4 w-full'>
            <div className='w-full'>
              {itemSelected ? (
                <InputField
                  label='Stock Remaining'
                  type='number'
                  name='stock'
                  value={item.stock}
                  onChange={handleChange}
                  min='0'
                />
              ) : (
                <InputField
                  label='Stock Added'
                  type='number'
                  name='stockAdded'
                  value={item.stockAdded}
                  onChange={handleChange}
                  min='0'
                />
              )}
            </div>
            <div className='w-full'>
              <select
                id='dropdown'
                name='category'
                value={item.category}
                onChange={handleChange}
                className='h-14 w-full border border-gray-300 px-3 text-base focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent rounded-lg'
              >
                <option value='' disabled>
                  Choose A Category
                </option>
                {inventory.map((category, index) => (
                  <option key={index} value={category} className='capitalize'>
                    {category}
                  </option>
                ))}
              </select>
            </div>
            <div className='w-full'>
              <InputField
                label='Unit Price (UGX)'
                type='number'
                name='unitPrice'
                value={item.unitPrice}
                onChange={handleChange}
                min='0'
              />
            </div>
          </div>
          <div className='flex flex-col md:flex-row items-center justify-between gap-4 mb-4 w-full'>
            <div className='flex-[2] w-full'>
              <InputField
                label='Manufacturer'
                type='text'
                name='manufacturer'
                value={item.manufacturer}
                onChange={handleChange}
              />
            </div>
            <div className='flex-1 w-full'>
              <InputField
                label='Country Of Origin'
                type='text'
                name='countryOfOrigin'
                value={item.countryOfOrigin}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='flex flex-col md:flex-row items-center justify-between gap-4 mb-4 w-full'>
            <div className='flex-[2] w-full'>
              <InputField
                label='Batch Number'
                type='text'
                name='batchNumber'
                value={item.batchNumber}
                onChange={handleChange}
              />
            </div>
            {item.category && item.category !== 'Medical Equipment' && (
              <div className='flex-1 w-full'>
                <InputField
                  label='Expiry Date'
                  type='date'
                  name='dateOfExpiry'
                  value={item.dateOfExpiry}
                  onChange={handleChange}
                  date={true}
                />
              </div>
            )}
          </div>
          <div className='mb-4'>
            <InputField
              label='Storage Conditions'
              type='text'
              name='storageCondition'
              value={item.storageCondition}
              onChange={handleChange}
            />
          </div>
          <div className='mb-4'>
            <InputField
              label='Side Effects'
              type='text'
              name='sideEffects'
              value={item.sideEffects}
              onChange={handleChange}
            />
          </div>
          <div className='mb-4'>
            <label className='block text-gray-500 px-3'>Description:</label>
            <textarea
              name='description'
              value={item.description}
              onChange={handleChange}
              required
              className='border border-gray-300 rounded-lg p-2 w-full'
            />
          </div>
          <div className='flex flex-col md:flex-row items-center gap-5'>
            <Button
              label='Clear Fields'
              onClick={handleClear}
              className='px-3 py-2 w-full md:w-fit border-2 border-warning bg-warning/10 text-warning text-lg hover:bg-warning hover:border-warning hover:text-secondary rounded-lg'
            />
            <Button
              label='Cancel'
              onClick={handleCancel}
              className='px-3 py-2 w-full md:w-fit border-2 border-error bg-error/10 text-error text-lg hover:bg-error hover:border-error hover:text-secondary rounded-lg'
            />
            {itemSelected ? (
              <Button
                label={saving ? 'Updating Item ... ' : 'Update Item'}
                onClick={handleSubmit}
                className={`px-3 py-2 w-full md:w-fit border-2 border-success bg-success/10 text-success text-lg hover:bg-success hover:text-secondary rounded-lg ${
                  item.name === ''
                    ? 'cursor-not-allowed'
                    : saving
                    ? 'cursor-wait'
                    : 'cursor-pointer'
                }`}
                disabled={item.name === ''}
              />
            ) : (
              <Button
                label={saving ? 'Adding Item ... ' : 'Add Item'}
                onClick={handleSubmit}
                className={`px-3 py-2 w-full md:w-fit border-2 border-success bg-success/10 text-success text-lg hover:bg-success hover:text-secondary rounded-lg ${
                  item.name === ''
                    ? 'cursor-not-allowed'
                    : saving
                    ? 'cursor-wait'
                    : 'cursor-pointer'
                }`}
                disabled={item.name === ''}
              />
            )}
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddItem;
