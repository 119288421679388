import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const DashboardLineChart = ({ data, dataKey }) => {
  return (
    <div className='my-4'>
      <ResponsiveContainer width='100%' height={200}>
        <p className='capitalize my-2'>{dataKey} overtime</p>
        <LineChart data={data}>
          <XAxis dataKey='name' />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type='monotone' dataKey={dataKey} stroke='rgb(3, 4, 94)' />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DashboardLineChart;
