import React, { useContext, useEffect, useRef, useState } from 'react';
import { HiBars3 } from 'react-icons/hi2';
import { profile_pic } from '../assets';
import User from './User';
import { AuthContext } from '../context/AuthProvider';

const TopBar = ({ isMobileMenuOpen, toggleMobileMenu }) => {
  const { user } = useContext(AuthContext);

  const [userDetails, setUserDetails] = useState(false);

  const userDetailsRef = useRef(null);

  const openUserDetails = () => {
    setUserDetails(!userDetails);
  };

  // Function to handle clicks outside the details section
  const handleClickOutside = (event) => {
    if (
      userDetailsRef.current &&
      !userDetailsRef.current.contains(event.target)
    ) {
      setUserDetails(false);
    }
  };

  //Effect to handle click outside
  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className=''>
      <div className='flex items-center justify-between gap-2 bg-white sticky top-0 left-0 py-3 px-5 shadow-lg rounded-lg z-40'>
        <div className='flex items-center justify-center gap-3'>
          {/* Hamburger Icon */}
          <div className='flex items-center lg:hidden'>
            <button onClick={toggleMobileMenu}>
              {!isMobileMenuOpen && <HiBars3 size={24} />}
            </button>
          </div>
          <div className='font-urbanist font-light text-xl md:text-2xl'>
            Amazing Grace Pharmacy
          </div>
        </div>

        <div className='flex items-center gap-5 relative'>
          <div className='hidden md:flex flex-col items-center justify-center'>
            <div className='flex items-end justify-center gap-2 text-xl'>
              <p className='font-thin'>Hello</p>
              <p className='font-bold uppercase'>{user?.firstName}</p>
            </div>
            <div className='h-[2px] bg-black/50 border w-[80%] rounded-full'></div>
          </div>
          <div
            onClick={openUserDetails}
            className='border font-extralight rounded-[50%] p-2 hover:bg-gray-200 cursor-pointer'
          >
            <img
              className=' object-contain w-5 h-5'
              src={profile_pic}
              alt='profile_pic'
              srcSet=''
            />
          </div>
          {userDetails && (
            <div
              ref={userDetailsRef}
              className='absolute w-[250px] top-[60px] right-0 bg-white shadow-lg rounded-lg z-40'
            >
              <User setUserDetails={setUserDetails} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
