import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import Login from './pages/authentication/Login';
import Dashboard from './components/Dashboard';
import { InventoryProvider } from './context/InventoryProvider';
import { OrderProvider } from './context/OrderProvider';
import { AuthProvider, AuthContext } from './context/AuthProvider';
import { useContext } from 'react';

const MainRoutes = () => {
  const { user } = useContext(AuthContext);

  return (
    <InventoryProvider>
      <OrderProvider>
        <Routes>
          {/* If user is not logged in, show only the login route */}
          {!user ? (
            <>
              <Route path='/' element={<Navigate to='/login' replace />} />
              <Route path='/login' element={<Login />} />
              {/* Redirect any other routes to login */}
              <Route path='*' element={<Navigate to='/login' replace />} />
            </>
          ) : (
            <>
              {/* Render the dashboard and other routes if user is logged in */}
              <Route
                path='/'
                element={<Navigate to='/pharmacy/dashboard' replace />}
              />
              <Route path='/pharmacy/:screen?' element={<Dashboard />} />
              <Route
                path='/pharmacy/inventory/:item?'
                element={<Dashboard />}
              />
              <Route
                path='/pharmacy/inventory/add_item'
                element={<Dashboard />}
              />
              <Route
                path='/pharmacy/inventory/:category?/:item?'
                element={<Dashboard />}
              />
              <Route path='/pharmacy/orders/:item?' element={<Dashboard />} />

              {/* Redirect any other routes to dashboard */}
              <Route
                path='*'
                element={<Navigate to='/pharmacy/dashboard' replace />}
              />
            </>
          )}
        </Routes>
      </OrderProvider>
    </InventoryProvider>
  );
};

function App() {
  return (
    <div className='bg-background text-textLight font-mulish'>
      <Router>
        <AuthProvider>
          <MainRoutes />
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
