import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';
import { facilityDetails } from '../constants/facility';

// Define styles
const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 10,
    fontFamily: 'Helvetica',
  },
  pageTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    marginBottom: 20,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  text: {
    marginBottom: 10,
  },
  tableContainer: {
    marginVertical: 10,
  },
  tableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#000',
    marginBottom: 5,
  },
  tableRow: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  tableColHeader: {
    width: '20%',
    fontWeight: 'bold',
  },
  tableCol: {
    width: '20%',
  },
  chartPlaceholder: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 230,
    backgroundColor: '#f0f0f0',
    marginBottom: 10,
    textAlign: 'center',
    borderRadius: 10,
  },
  chartHeading: { paddingVertical: 20, fontSize: 12 },
});

// The PDF Document component
const InventoryItemsReport = ({ startDate, endDate, reportData, user }) => {
  //Number of items in stock
  const totalItemsInStock = reportData.length;

  // Items low in stock
  const lowStockItems = reportData.filter((item) => {
    const category = item.category;
    const stock = item.stock;

    if (
      category === 'Tablets and Capsules' ||
      category === 'Supplements and Vitamins' ||
      category === 'Liquids and Vials' ||
      category === 'Others'
    ) {
      return stock < 50;
    }

    if (
      category === 'Medical Equipment' ||
      category === 'Syrups' ||
      category === 'Creams and Lotions' ||
      category === 'Ear, Eyes and Nose Drops'
    ) {
      return stock < 5;
    }

    if (category === 'Personal Protective Equipment (PPE)') {
      return stock < 10;
    }

    return false; // For categories not specified, no low stock criteria
  });

  // Number of expired  items
  const currentDate = new Date();

  const expiredItems = reportData.filter((item) => {
    const expiryDate = new Date(item.dateOfExpiry);
    return expiryDate < currentDate;
  });

  // Total Stock Value
  const totalStockValue = reportData.reduce((total, item) => {
    const itemValue = item.stock * item.unitPrice;
    return total + itemValue;
  }, 0);

  return (
    <Document>
      {/* Page 1: Inventory Summary */}
      <Page size='A4' style={styles.page}>
        <View style={styles.pageTitle}>
          <Text style={styles.header}>{facilityDetails.name}</Text>
          <Text style={styles.text}>Pharmacy Inventory Report</Text>
          <Text style={styles.text}>
            {startDate} to {endDate}
          </Text>
        </View>

        <View
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            gap: 5,
            marginVertical: 20,
            fontWeight: 'bold',
            fontSize: 12,
          }}
        >
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              gap: 5,
            }}
          >
            <Text>Report Created On:</Text>
            <Text> {new Date().toISOString().split('T')[0]}</Text>
          </View>
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              gap: 5,
            }}
          >
            <Text>Created By:</Text>
            <Text>
              {' '}
              {user && user?.firstName} {user && user?.lastName}
            </Text>
          </View>
        </View>

        {/* Inventory Summary */}
        <View>
          <Text style={styles.sectionTitle}>Inventory Summary</Text>
          <Text style={styles.text}>
            Total Stock Value: UGX{' '}
            {new Intl.NumberFormat('en-US').format(totalStockValue)}
          </Text>
          <Text style={styles.text}>
            Total Items in Stock: {totalItemsInStock}{' '}
            {totalItemsInStock.length === 1 ? 'product' : 'products'}
          </Text>
          <Text style={styles.text}>
            Items Low in Stock: {lowStockItems.length}{' '}
            {lowStockItems.length === 1 ? 'product' : 'products'}
          </Text>
          <Text style={styles.text}>
            Expired Items: {expiredItems.length}{' '}
            {expiredItems.length === 1 ? 'product' : 'products'}
          </Text>
        </View>

        {/* Placeholder for visuals */}
        <View>
          <View style={styles.chartPlaceholder}>
            <Text style={styles.chartHeading}>
              Stock Value by Category (Pie Chart)
            </Text>
          </View>

          <View style={styles.chartPlaceholder}>
            <Text style={styles.chartHeading}>
              Top Low-Stock Products (Bar Chart)
            </Text>
          </View>
        </View>
      </Page>

      {/* Page 2: Condensed Inventory Data */}
      <Page size='A4' style={styles.page}>
        <Text style={styles.sectionTitle}>Condensed Inventory Data</Text>
        {/* Table */}
        <View style={styles.tableContainer}>
          {/* Table Header */}
          <View style={styles.tableHeader}>
            <Text style={styles.tableColHeader}>Product Name</Text>
            <Text style={styles.tableColHeader}>Stock Quantity (units)</Text>
            <Text style={styles.tableColHeader}>Unit Price (UGX)</Text>
            <Text style={styles.tableColHeader}>Category</Text>
            <Text style={styles.tableColHeader}>Expiry Date</Text>
          </View>

          {/* Table Rows */}
          {reportData.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.tableCol}>{item.name}</Text>
              <Text style={styles.tableCol}>{item.stock}</Text>
              <Text style={styles.tableCol}>
                {new Intl.NumberFormat('en-US').format(item.unitPrice)}
              </Text>
              <Text style={styles.tableCol}>{item.category}</Text>
              <Text style={styles.tableCol}>
                {new Date(item.dateOfExpiry).toLocaleDateString()}
              </Text>
            </View>
          ))}
        </View>
      </Page>

      {/* Page 3: Low Stock Items & Expired Products */}
      <Page size='A4' style={styles.page}>
        <Text style={styles.sectionTitle}>Items Low in Stock</Text>
        {/* Table */}
        <View style={styles.tableContainer}>
          {/* Table Header */}
          <View style={styles.tableHeader}>
            <Text style={styles.tableColHeader}>Product Name</Text>
            <Text style={styles.tableColHeader}>Stock Quantity (units)</Text>
          </View>

          {lowStockItems.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.tableCol}>{item.name}</Text>
              <Text style={styles.tableCol}>{item.stock}</Text>
            </View>
          ))}
        </View>

        <Text style={styles.sectionTitle}>Expired Products</Text>
        {/* Table */}
        <View style={styles.tableContainer}>
          <View style={styles.tableHeader}>
            <Text style={styles.tableColHeader}>Product Name</Text>
            <Text style={styles.tableColHeader}>Expiry Date</Text>
          </View>

          {expiredItems.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.tableCol}>{item.name}</Text>
              <Text style={styles.tableCol}>
                {new Date(item.dateOfExpiry).toLocaleDateString()}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default InventoryItemsReport;
