import React, { createContext, useState } from 'react';

// Create the context
export const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
  const [pageSelected, setPageSelected] = useState('');
  const [orders, setOrders] = useState();
  const [suppliers, setSuppliers] = useState();
  const [orderStatusChange, setOrderStatusChange] = useState(false);
  const [refetch, setRefetch] = useState(false);

  return (
    <OrderContext.Provider
      value={{
        pageSelected,
        setPageSelected,
        orders,
        setOrders,
        suppliers,
        setSuppliers,
        orderStatusChange,
        setOrderStatusChange,
        refetch,
        setRefetch,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};
