import React from 'react';
import { IoTrashOutline } from 'react-icons/io5';
import { FiMinus, FiPlus } from 'react-icons/fi';

const Cart = ({ cart, setCart, removeItem, calculateTotal }) => {
  // Function to add quantity of the item
  const addQuantity = (item, index) => {
    const newCart = [...cart];
    if (newCart[index].quantity < item.stock) {
      newCart[index].quantity += 1; // Increment quantity
      setCart(newCart);
      calculateTotal(newCart); // Update total
    }
  };

  // Function to reduce quantity of the item
  const reduceQuantity = (index) => {
    const newCart = [...cart];
    if (newCart[index].quantity > 1) {
      newCart[index].quantity -= 1; // Decrement quantity
      setCart(newCart);
      calculateTotal(newCart); // Update total
    }
  };

  return (
    <div
      className={`w-full h-fit p-2 rounded-lg  ${
        cart.length > 0 && 'bg-white shadow-lg'
      }`}
    >
      {cart.length > 0 ? (
        <table className='w-full p-2'>
          <thead className='sticky top-2 w-full text-white'>
            <tr className='text-left'>
              <th className='p-3 text-center rounded-l-lg bg-primary'>No.</th>
              <th className='p-3 bg-primary'>Item</th>
              <th className='p-3 text-center bg-primary'>Unit Price</th>
              <th className='p-3 text-center bg-primary'>Quantity</th>
              <th className='p-3 text-center bg-primary'>Sub Total</th>
              <th className='p-3 text-center rounded-r-lg bg-primary'></th>
            </tr>
          </thead>
          <tbody>
            {cart.map((item, index) => (
              <tr
                className={`hover:bg-gray-200 cursor-pointer ${
                  (index + 1) % 2 === 0 ? 'bg-gray-100' : 'bg-white'
                }`}
                key={index}
              >
                <td className='p-2 rounded-l-lg text-center'>{index + 1}</td>
                <td className='p-2'>
                  <div>{item.name}</div>
                  <div className='text-[12px]'>Stock: {item.stock}</div>
                </td>
                <td className='p-2 text-center'>
                  {new Intl.NumberFormat('en-US').format(item.unitPrice)}
                </td>
                <td className='p-2 text-center'>
                  <div className='flex items-center justify-center gap-2'>
                    <div
                      className='flex items-center justify-center hover:bg-accent/50 hover:text-white w-8 h-8 rounded-full'
                      onClick={() => reduceQuantity(index)}
                    >
                      <FiMinus />
                    </div>
                    <input
                      type='number'
                      className='bg-transparent text-center w-14 text-lg'
                      value={item.quantity} // Use item.quantity here
                      onChange={(e) => {
                        const newQuantity = Math.max(
                          1,
                          (parseInt(e.target.value) <= item.stock &&
                            parseInt(e.target.value)) ||
                            1
                        ); // Ensure quantity is at least 1 and less than available stock
                        const newCart = [...cart];
                        newCart[index].quantity = newQuantity; // Update quantity
                        setCart(newCart);
                        calculateTotal(newCart); // Update total
                      }}
                    />
                    <div
                      className='flex items-center justify-center -ml-4 hover:bg-accent/50 hover:text-white w-8 h-8 rounded-full'
                      onClick={() => addQuantity(item, index)}
                    >
                      <FiPlus />
                    </div>
                  </div>
                </td>
                <td className='p-2 text-center'>
                  {new Intl.NumberFormat('en-US').format(
                    item.unitPrice * item.quantity
                  )}
                </td>
                <td className='p- rounded-r-lg'>
                  <div
                    onClick={() => removeItem(item._id)}
                    className='flex items-center justify-center w-8 h-8 rounded-full text-error hover:text-white hover:bg-error'
                  >
                    <IoTrashOutline />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className='flex items-center justify-center h-40 text-2xl'>
          <p>Add items to the Cart</p>
        </div>
      )}
    </div>
  );
};

export default Cart;
