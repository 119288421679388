import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { facilityDetails } from '../constants/facility';

// Define styles
const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 10,
    fontFamily: 'Helvetica',
  },
  pageTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    marginBottom: 20,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  text: {
    marginBottom: 10,
  },
  tableContainer: {
    marginVertical: 10,
  },
  tableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#000',
    marginBottom: 5,
  },
  tableRow: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  tableColHeader: {
    width: '25%',
    fontWeight: 'bold',
  },
  tableCol: {
    width: '25%',
  },
  chartPlaceholder: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 230,
    backgroundColor: '#f0f0f0',
    marginBottom: 10,
    textAlign: 'center',
    borderRadius: 10,
  },
  chartHeading: { paddingVertical: 20, fontSize: 12 },
});

// The PDF Document component
const SuppliersReport = ({ startDate, endDate, reportData, user }) => {
  // Calculate total suppliers and other metrics
  const totalSuppliers = reportData.length;
  const topSupplier = reportData.reduce(
    (prev, current) =>
      prev.totalAmount > current.totalAmount ? prev : current,
    {}
  );
  const averageOrderSize =
    reportData.reduce((total, supplier) => total + supplier.totalAmount, 0) /
    totalSuppliers;
  const ordersFromTop5Suppliers = reportData
    .slice(0, 5)
    .reduce((total, supplier) => total + supplier.totalOrders, 0);

  console.log(reportData);

  return (
    <Document>
      {/* Page 1: Supplier Summary */}
      <Page size='A4' style={styles.page}>
        <View style={styles.pageTitle}>
          <Text style={styles.header}>{facilityDetails.name}</Text>
          <Text style={styles.text}>Pharmacy Supplier Report</Text>
          <Text style={styles.text}>
            {startDate} to {endDate}
          </Text>
        </View>

        <View
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            gap: 5,
            marginVertical: 20,
            fontWeight: 'bold',
            fontSize: 12,
          }}
        >
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              gap: 5,
            }}
          >
            <Text>Report Created On:</Text>
            <Text> {new Date().toISOString().split('T')[0]}</Text>
          </View>
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              gap: 5,
            }}
          >
            <Text>Created By:</Text>
            <Text>
              {' '}
              {user && user?.firstName} {user && user?.lastName}
            </Text>
          </View>
        </View>

        {/* Supplier Summary */}
        <Text style={styles.sectionTitle}>Supplier Summary</Text>
        <Text style={styles.text}>Total Suppliers: {totalSuppliers}</Text>
        <Text style={styles.text}>Top Supplier: {topSupplier.name}</Text>
        <Text style={styles.text}>
          Average Order Size: UGX{' '}
          {new Intl.NumberFormat('en-US').format(averageOrderSize)}
        </Text>
        <Text style={styles.text}>
          Orders from Top 5 Suppliers: {ordersFromTop5Suppliers}
        </Text>

        {/* Placeholder for visuals */}
        <View style={styles.chartPlaceholder}>
          <Text style={styles.chartHeading}>
            Orders by Supplier (Pie Chart)
          </Text>
        </View>

        <View style={styles.chartPlaceholder}>
          <Text style={styles.chartHeading}>Monthly Orders (Line Chart)</Text>
        </View>
      </Page>

      {/* Page 2: Detailed Supplier Data */}
      <Page size='A4' style={styles.page}>
        <Text style={styles.sectionTitle}>Detailed Supplier Information</Text>
        {/* Table */}
        <View style={styles.tableContainer}>
          {/* Table Header */}
          <View style={styles.tableHeader}>
            <Text style={styles.tableColHeader}>Supplier Name</Text>
            <Text style={styles.tableColHeader}>Total Orders</Text>
            <Text style={styles.tableColHeader}>Total Amount</Text>
            <Text style={styles.tableColHeader}>Contact Information</Text>
          </View>

          {/* Table Rows */}
          {reportData.map((supplier, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.tableCol}>{supplier.name}</Text>
              <Text style={styles.tableCol}>{supplier.totalOrders} orders</Text>
              <Text style={styles.tableCol}>
                UGX{' '}
                {new Intl.NumberFormat('en-US').format(supplier.totalAmount)}
              </Text>
              <Text style={styles.tableCol}>{supplier.contactInfo}</Text>
            </View>
          ))}
        </View>
      </Page>

      {/* Page 3: Supplier Performance */}
      <Page size='A4' style={styles.page}>
        <Text style={styles.sectionTitle}>Supplier Performance</Text>
        <View style={styles.chartPlaceholder}>
          <Text style={styles.chartHeading}>
            Supplier Performance Comparison (Bar Chart)
          </Text>
        </View>

        <Text style={styles.sectionTitle}>Top 5 Suppliers</Text>
        {/* Table */}
        <View style={styles.tableContainer}>
          {/* Table Header */}
          <View style={styles.tableHeader}>
            <Text style={styles.tableColHeader}>Supplier Name</Text>
            <Text style={styles.tableColHeader}>Total Orders</Text>
            <Text style={styles.tableColHeader}>Fulfilled Orders</Text>
            <Text style={styles.tableColHeader}>Rating</Text>
          </View>

          {reportData.slice(0, 5).map((supplier, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.tableCol}>{supplier.name}</Text>
              <Text style={styles.tableCol}>{supplier.totalOrders} orders</Text>
              <Text style={styles.tableCol}>
                {supplier.fulfilledOrders} fulfilled
              </Text>
              <Text style={styles.tableCol}>{supplier.rating}/5</Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default SuppliersReport;
