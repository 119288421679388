//Colors to be used in the project
const colors = {
  primary: '#03045E', // Blue (Primary color for buttons, highlights)
  secondary: '#FFFFFF', // White (Secondary color for backgrounds or text)
  accent: '#0077B6', // Darker Blue for accents
  background: '#DAF5FB', // Light Grayish background (to contrast with white text)
  text: '#1E3A8A', // Dark Blue for primary text
  textLight: '#6B7280', // Lighter gray text for subtitles
  error: '#DC2626', // Error messages
  success: '#16A34A', // Success messages
  warning: '#F97316', // Warnings
};

export default colors;
