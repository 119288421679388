import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import CreateUserInputForm from './CreateUserInputForm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import colors from '../assets/colors';

const NewUser = ({
  users,
  editUser,
  closeNewUserModal,
  refetch,
  setRefetch,
  selectedUser,
}) => {
  const [oldUser, setOldUser] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    isAdmin: '',
    isCashier: '',
  });
  const [saving, setSaving] = useState(false);

  const token = localStorage.getItem('token');

  // Find user with the selected id
  const toEdit = users.find((user) => user._id === selectedUser);

  // Effect to populate the form when selected user changes
  useEffect(() => {
    if (selectedUser) {
      setOldUser({
        firstName: toEdit.firstName,
        lastName: toEdit.lastName,
        username: toEdit.username,
        email: toEdit.email,
        password: toEdit.password,
        isAdmin: toEdit.isAdmin,
        isCashier: toEdit.isCashier,
      });
    }
  }, [selectedUser]);

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setOldUser({ ...oldUser, [name]: type === 'checkbox' ? checked : value });
  };

  //Function to save user
  const handleSaveUser = async (e) => {
    e.preventDefault();
    setSaving(true);

    const newUser = {
      firstName: oldUser.firstName,
      lastName: oldUser.lastName,
      username: oldUser.username,
      email: oldUser.email,
      password: oldUser.password,
      isAdmin: oldUser.isAdmin || false,
      isCashier: oldUser.isCashier || false,
    };

    const url = editUser
      ? `${process.env.REACT_APP_API_URL}/users/updateuser/${selectedUser}`
      : `${process.env.REACT_APP_API_URL}/users/createuser`;

    const method = editUser ? 'put' : 'post';

    // Show a "Saving..." toast notification while data is being processed
    const savingToastId = toast.info(
      `${
        editUser ? 'Updating' : 'Saving'
      } ${newUser.firstName.toUpperCase()} ...`,
      {
        position: 'top-center',
        autoClose: false, // Keep it open until dismissed manually
        style: {
          backgroundColor: '#FFD700', // Customize the background color (yellowish for "saving")
          color: '#000',
          textAlign: 'center',
        },
        icon: false,
        hideProgressBar: true,
      }
    );

    try {
      await axios({
        method,
        url,
        data: newUser,
        headers: {
          token: `Bearer ${token}`,
        },
      });

      setRefetch(!refetch);

      const successMessage = editUser
        ? `${newUser.firstName}'s details have been updated successfully.`
        : `${newUser.firstName} has been added successfully.`;

      // Dismiss the "Saving..." toast
      toast.dismiss(savingToastId);

      toast.success(`${successMessage}`, {
        position: 'top-center',
        autoClose: 500,
        // Styling the pop-up message
        style: {
          backgroundColor: `${colors.success}`,
          color: '#fff',
          textAlign: 'center',
        },
        icon: false,
        // Remove the progress bar
        hideProgressBar: true,
      });

      setSaving(false);
      closeNewUserModal();
    } catch (error) {
      // Dismiss the "Saving..." toast
      toast.dismiss(savingToastId);

      toast.error(`Error ${editUser ? 'updating' : 'creating'} user:`, {
        position: 'top-center',
        icon: false,
        // Remove the progress bar
        hideProgressBar: true,
        // Styling the pop-up message
        style: {
          backgroundColor: `${colors.error}`,
          color: '#fff',
          textAlign: 'center',
        },
      });
      console.error(
        `Error ${editUser ? 'updating' : 'creating'} user:`,
        error.response ? error.response.data : error.message
      );
      setSaving(false);
    }
  };

  return (
    <div className='fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75'>
      <div
        className='flex flex-col gap-2 bg-white p-5 overflow-auto w-[90%] md:w-[50%] rounded-lg'
        style={{ maxHeight: 'calc(100vh - 40px)' }}
      >
        <div className='flex items-center justify-end '>
          <h2 className='flex-1 text-center text-gray-800 text-2xl'>
            {editUser ? 'Edit User' : 'Add A New User'}
          </h2>
          <div
            className='cursor-pointer text-red-600 hover:bg-red-600 hover:text-white p-1 rounded-md border border-red-600 text-xl'
            onClick={closeNewUserModal}
          >
            <IoClose />
          </div>
        </div>
        <div className='my-3'>
          <CreateUserInputForm
            oldUser={oldUser}
            editUser={editUser}
            handleSaveUser={handleSaveUser}
            handleChange={handleChange}
            saving={saving}
            setSaving={setSaving}
          />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default NewUser;
