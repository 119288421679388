import React, { useState } from 'react';
import Button from './Button';
import axios from 'axios';
import DeleteModal from './DeleteUserModal'; // Import the DeleteModal component

const UsersList = ({
  users,
  refetch,
  setRefetch,
  setNewUserModal,
  setEditUser,
  setSelectedUser,
}) => {
  const token = localStorage.getItem('token');

  // State to manage the modal visibility and selected user
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  // Function to edit a user's details
  const handleEdit = (id) => {
    setSelectedUser(id);
    setEditUser(true);
    setNewUserModal(true);
  };

  // Function to delete a user
  const handleDelete = async () => {
    if (!userToDelete) return; // If no user to delete, exit the function

    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/users/deleteuser/${userToDelete}`,
        {
          headers: {
            token: `Bearer ${token}`,
          },
        }
      );
      setRefetch(!refetch);
    } catch (err) {
      console.error('Error deleting user:', err);
    } finally {
      setIsModalOpen(false); // Close the modal
      setUserToDelete(null); // Reset the user to delete
    }
  };

  // Open the delete confirmation modal
  const confirmDelete = (id) => {
    setUserToDelete(id);
    setIsModalOpen(true);
  };

  return (
    <div
      className={`h-fit p-2 rounded-lg overflow-y-auto ${
        users.length > 0 ? 'bg-white shadow-lg' : ''
      }`}
    >
      <table className='table-auto w-full p-2'>
        <thead className='sticky top-0 w-full text-white'>
          <tr className='text-left'>
            <th className='px-2 py-3 text-center rounded-l-lg bg-primary'>No.</th>
            <th className='px-2 py-3 bg-primary'>First Name</th>
            <th className='px-2 py-3 bg-primary'>Last Name</th>
            <th className='px-2 py-3 bg-primary'>Username</th>
            <th className='px-2 py-3 bg-primary'>Email</th>
            <th className='px-2 py-3 text-center bg-primary rounded-r-lg truncate max-w-[80px]'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users?.map((user, index) => (
            <tr
              key={index}
              className={`hover:bg-gray-200 cursor-pointer ${
                (index + 1) % 2 === 0 ? 'bg-gray-100' : 'bg-white'
              }`}
            >
              <td className='p-2 rounded-l-lg text-center'>{index + 1}</td>
              <td className='p-2 capitalize'>{user.firstName}</td>
              <td className='p-2 capitalize'>{user.lastName}</td>
              <td className='p-2 lowercase'>{user.username}</td>
              <td className='p-2 '>{user.email}</td>
              <td className='p-2 flex flex-col sm:flex-row gap-2 items-center justify-center rounded-r-lg'>
                <Button
                  label='Edit'
                  className='p-2 border rounded-lg w-full bg-green-50/50 border-green-600 text-green-600 hover:bg-green-600 hover:text-white'
                  onClick={() => handleEdit(user._id)}
                />
                <Button
                  label='Delete'
                  className='p-2 border rounded-lg w-full bg-red-50 border-red-600 text-red-600 hover:bg-red-600 hover:text-white'
                  onClick={() => confirmDelete(user._id)} // Open modal on click
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Delete Modal for delete confirmation */}
      <DeleteModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        onConfirm={handleDelete} 
      />
    </div>
  );
};

export default UsersList;
