import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { facilityDetails } from '../constants/facility';

// Define styles
const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 10,
    fontFamily: 'Helvetica',
  },
  pageTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    marginBottom: 20,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  text: {
    marginBottom: 10,
  },
  tableContainer: {
    marginVertical: 10,
  },
  tableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#000',
    marginBottom: 5,
  },
  tableRow: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  tableColHeader: {
    width: '20%',
    fontWeight: 'bold',
  },
  tableCol: {
    width: '20%',
  },
  chartPlaceholder: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 250,
    backgroundColor: '#f0f0f0',
    marginBottom: 10,
    textAlign: 'center',
    borderRadius: 10,
  },
  chartHeading: { paddingVertical: 20, fontSize: 12 },
});

// The PDF Document component
const OrdersReport = ({ startDate, endDate, reportData, user }) => {
  const pendingOrders = reportData.filter(
    (ord) => ord.status === 'pending'
  ).length;

  const deliveredOrders = reportData.filter(
    (ord) => ord.status === 'delivered'
  ).length;

  const totalAmountSpent = reportData.reduce(
    (total, ord) => total + ord.totalAmount,
    0
  );

  return (
    <Document>
      {/* Page 1: Orders Summary */}
      <Page size='A4' style={styles.page}>
        <View style={styles.pageTitle}>
          <Text style={styles.header}>{facilityDetails.name}</Text>
          <Text style={styles.text}>Pharmacy Orders Report</Text>
          <Text style={styles.text}>
            {startDate} to {endDate}
          </Text>
        </View>

        <View
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            gap: 5,
            marginVertical: 20,
            fontWeight: 'bold',
            fontSize: 12,
          }}
        >
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              gap: 5,
            }}
          >
            <Text>Report Created On:</Text>
            <Text> {new Date().toISOString().split('T')[0]}</Text>
          </View>
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              gap: 5,
            }}
          >
            <Text>Created By:</Text>
            <Text>
              {' '}
              {user && user?.firstName} {user && user?.lastName}
            </Text>
          </View>
        </View>

        {/* Orders Summary */}
        <View>
          <Text style={styles.sectionTitle}>Order Summary</Text>
          <Text style={styles.text}>
            Total Orders Placed: {reportData.length}
          </Text>
          <Text style={styles.text}>
            Total Amount Spent: UGX{' '}
            {new Intl.NumberFormat('en-US').format(totalAmountSpent)}
          </Text>
          <Text style={styles.text}>Pending Orders: {pendingOrders}</Text>
          <Text style={styles.text}>Fulfilled Orders: {deliveredOrders}</Text>
        </View>

        {/* Placeholder for visuals */}
        <View style={styles.chartPlaceholder}>
          <Text style={styles.chartHeading}>
            Orders by Supplier (Pie Chart)
          </Text>
        </View>

        <View style={styles.chartPlaceholder}>
          <Text style={styles.chartHeading}>
            Monthly Order Trend (Line Chart)
          </Text>
        </View>
      </Page>

      {/* Page 2: Condensed Orders Data */}
      <Page size='A4' style={styles.page}>
        <Text style={styles.sectionTitle}>Recent Orders</Text>
        {/* Table */}
        <View style={styles.tableContainer}>
          {/* Table Header */}
          <View style={styles.tableHeader}>
            <Text style={styles.tableColHeader}>Order ID</Text>
            <Text style={styles.tableColHeader}>Supplier</Text>
            <Text style={styles.tableColHeader}>Order Date</Text>
            <Text style={styles.tableColHeader}>Status</Text>
            <Text style={styles.tableColHeader}>Total Amount (UGX)</Text>
          </View>

          {/* Table Rows */}
          {reportData.map((order, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.tableCol}>{order.id}</Text>
              <Text style={styles.tableCol}>{order.supplier}</Text>
              <Text style={styles.tableCol}>
                {new Date(order.createdAt).toISOString().split('T')[0]}
              </Text>
              <Text style={styles.tableCol}>{order.status}</Text>
              <Text style={styles.tableCol}>
                {new Intl.NumberFormat('en-US').format(order.totalAmount)}
              </Text>
            </View>
          ))}
        </View>
      </Page>

      {/* Page 3: Pending Orders */}
      <Page size='A4' style={styles.page}>
        <Text style={styles.sectionTitle}>Pending Orders</Text>
        {/* Table */}
        <View style={styles.tableContainer}>
          {/* Table Header */}
          <View style={styles.tableHeader}>
            <Text style={styles.tableColHeader}>Order ID</Text>
            <Text style={styles.tableColHeader}>Supplier</Text>
            <Text style={styles.tableColHeader}>Order Date</Text>
            <Text style={styles.tableColHeader}>Total Amount (UGX)</Text>
          </View>

          {reportData
            .filter((order) => order.status === 'pending')
            .map((order, index) => (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.tableCol}>{order.id}</Text>
                <Text style={styles.tableCol}>{order.supplier}</Text>
                <Text style={styles.tableCol}>
                  {new Date(order.createdAt).toISOString().split('T')[0]}
                </Text>
                <Text style={styles.tableCol}>
                  {new Intl.NumberFormat('en-US').format(order.totalAmount)}
                </Text>
              </View>
            ))}
        </View>
      </Page>
    </Document>
  );
};

export default OrdersReport;
