import React, { useContext } from 'react';
import { profile_pic } from '../assets';
import Button from './Button';
import { AuthContext } from '../context/AuthProvider';

const User = () => {
  const { user, logout } = useContext(AuthContext);

  return (
    <div className='flex flex-col items-center justify-center p-5'>
      <div className='p-2 rounded-[50%]'>
        <img
          className=' object-contain w-16 h-16'
          src={profile_pic}
          alt='profile_pic'
          srcSet=''
        />
      </div>
      <p>{user?.username}</p>
      <p>
        {user?.firstName} {user?.lastName}
      </p>
      <div className='w-full'>
        <Button
          label='log out'
          onClick={logout}
          className='px-3 py-1 w-full mt-2 bg-primary hover:bg-accent text-secondary rounded-lg'
        />
      </div>
    </div>
  );
};

export default User;
