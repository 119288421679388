import React, { useContext } from 'react';
import { InventoryContext } from '../context/InventoryProvider';

const InventoryList = () => {
  const { handleItemSelection, searchResult } = useContext(InventoryContext);

  return (
    <div
      className={` h-fit p-2 rounded-lg overflow-x-auto ${
        searchResult.length > 0 && 'bg-white shadow-lg'
      }`}
    >
      {searchResult.length > 0 ? (
        <table className='table-auto w-full p-2'>
          <thead className='sticky top-0 w-full text-white'>
            <tr className='text-left'>
              <th className='px-2 py-3 text-center rounded-l-lg bg-primary'>
                No.
              </th>
              <th className='px-2 py-3 bg-primary'>Name</th>
              <th className='px-2 py-3 bg-primary truncate max-w-[80px]'>
                Manufacturer
              </th>
              <th className='px-2 py-3 bg-primary truncate max-w-[80px]'>
                Country of Origin
              </th>
              <th className='px-2 py-3 bg-primary truncate max-w-[80px]'>
                Batch No.
              </th>
              <th className='px-2 py-3 bg-primary truncate max-w-[80px]'>
                Expiry Date
              </th>
              <th className='px-2 py-3 text-center bg-primary truncate max-w-[80px]'>
                Quantity
              </th>
              <th className='px-2 py-3 text-center bg-primary rounded-r-lg truncate max-w-[80px]'>
                Unit Price (UGX)
              </th>
            </tr>
          </thead>
          <tbody>
            {searchResult.map((item, index) => (
              <tr
                key={index}
                onClick={() => {
                  handleItemSelection(item);
                }}
                className={`hover:bg-gray-200 cursor-pointer ${
                  (index + 1) % 2 === 0 ? 'bg-gray-100' : 'bg-white'
                }`}
              >
                <td className='p-2 rounded-l-lg text-center'>{index + 1}</td>
                <td className='p-2'>{item.name}</td>
                <td className='p-2'>{item.manufacturer}</td>
                <td className='p-2'>{item.countryOfOrigin}</td>
                <td className='p-2 '>{item.batchNumber}</td>
                <td className='p-2 text-nowrap'>
                  {item.dateOfExpiry
                    ? new Date(item.dateOfExpiry).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })
                    : 'N/A'}
                </td>
                <td className='p-2 text-right'>
                  {new Intl.NumberFormat('en-US').format(item.stock)}
                </td>
                <td className='p-2 text-right rounded-r-lg'>
                  {new Intl.NumberFormat('en-US').format(item.unitPrice)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className='flex items-center justify-center h-40 text-2xl'>
          <p>No items available under this category</p>
        </div>
      )}
    </div>
  );
};

export default InventoryList;
