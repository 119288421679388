import React from 'react';
import ListAndStat from './ListAndStat';

const TabsAndCaps = () => {
  return (
    <div className='flex flex-col md:flex-row w-full'>
      <ListAndStat />
    </div>
  );
};

export default TabsAndCaps;
