import React from 'react';
import { IoReorderThreeOutline } from 'react-icons/io5';

const SettingsAndCollapse = ({ isOpen, setIsOpen }) => {
  const handleCloseSideBar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className='w-full'>
      <div className='flex flex-col gap-3 py-2 px-2 mx-0 font-light'>
        {/* <Link to='/pharmacy/settings'>
          <div
            className={`flex gap-2 items-center justify-start cursor-pointer px-2  py-1 hover:bg-accent hover:text-white rounded-md ${
              selectedScreen === 'settings' && 'text-white bg-primary'
            }`}
            onClick={() => handleScreenSelection('settings')}
          >
            <div className='text-xl md:text-lg p-1'>
              <IoSettingsOutline />
            </div>
            <div className={`${isOpen ? 'block' : 'hidden'}`}>Settings</div>
          </div>
        </Link> */}
        <div
          className='flex gap-2 items-center justify-start cursor-pointer px-2  py-1 hover:bg-accent rounded-md hover:text-white'
          onClick={handleCloseSideBar}
        >
          <div className='text-xl md:text-lg p-1'>
            <IoReorderThreeOutline />
          </div>
          <div className={`${isOpen ? 'block' : 'hidden'}`}>Collapse</div>
        </div>
      </div>
    </div>
  );
};

export default SettingsAndCollapse;
