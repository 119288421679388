import React, { useState } from 'react';
import PharmaSideBar from './PharmaSideBar';
import SettingsAndCollapse from './SettingsAndCollapse';

const SideBar = ({
  selectedScreen,
  setSelectedScreen,
  handleScreenSelection,
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  toggleMobileMenu,
  inventorySelected,
  setInventorySelected,
  handleInventorySelection,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className='flex justify-between flex-col my-5 h-full'>
      <PharmaSideBar
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selectedScreen={selectedScreen}
        setSelectedScreen={setSelectedScreen}
        handleScreenSelection={handleScreenSelection}
        isMobileMenuOpen={isMobileMenuOpen}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
        toggleMobileMenu={toggleMobileMenu}
        inventorySelected={inventorySelected}
        setInventorySelected={setInventorySelected}
        handleInventorySelection={handleInventorySelection}
      />

      <div className='hidden lg:flex'>
        <SettingsAndCollapse
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setSelectedScreen={setSelectedScreen}
          handleScreenSelection={handleScreenSelection}
          isMobileMenuOpen={isMobileMenuOpen}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
          toggleMobileMenu={toggleMobileMenu}
        />
      </div>
    </div>
  );
};

export default SideBar;
