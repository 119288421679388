import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios'; // Make sure to import axios
import PharmacySalesReport from '../../components/PharmacySalesReport';
import { pdf } from '@react-pdf/renderer';
import InventoryItemsReport from '../../components/InventoryItemsReport';
import OrdersReport from '../../components/OrdersReport';
import SuppliersReport from '../../components/SuppliersReport';
import { AuthContext } from '../../context/AuthProvider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import colors from '../../assets/colors';

const Reports = () => {
  const { user } = useContext(AuthContext);

  const [reportType, setReportType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [generate, setGenerate] = useState(false);

  const token = localStorage.getItem('token');

  // Function to fetch data from the backend based on the report type
  const fetchReportData = async () => {
    setLoading(true);
    setError(null);

    // Show a "Saving..." toast notification while data is being processed
    const savingToastId = toast.info(`Processing Report ...`, {
      position: 'top-center',
      autoClose: false, // Keep it open until dismissed manually
      style: {
        backgroundColor: '#FFD700', // Customize the background color (yellowish for "saving")
        color: '#000',
        textAlign: 'center',
      },
      icon: false,
      hideProgressBar: true,
    });

    try {
      // Make a POST request using axios
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/reports`,
        {
          reportType,
          startDate,
          endDate,
        },
        {
          headers: {
            token: `Bearer ${token}`,
          },
        }
      );

      // Check if response is JSON before parsing
      if (response.headers['content-type']?.includes('application/json')) {
        const data = response.data; // Axios automatically parses JSON
        setReportData(data);
      } else {
        throw new Error('Response is not in JSON format');
      }

      // Dismiss the "Saving..." toast
      toast.dismiss(savingToastId);
    } catch (err) {
      console.error('Error caught:', err);
      setError(err.message); // Catch and display any error
    } finally {
      setLoading(false); // Stop loading after fetch
    }
  };

  // Form submission handler to trigger data fetch
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form reload
    if (reportType) {
      fetchReportData(); // Fetch report data based on selected type
      setGenerate(true);
    }
  };

  // Effect to trigger PDF view when report is set
  useEffect(() => {
    const generatePDF = async () => {
      if (generate) {
        const blob = await pdf(
          reportType === 'sales' ? (
            <PharmacySalesReport
              startDate={startDate}
              endDate={endDate}
              reportData={reportData}
              user={user}
            />
          ) : reportType === 'inventoryitems' ? (
            <InventoryItemsReport
              startDate={startDate}
              endDate={endDate}
              reportData={reportData}
              user={user}
            />
          ) : reportType === 'orders' ? (
            <OrdersReport
              startDate={startDate}
              endDate={endDate}
              reportData={reportData}
              user={user}
            />
          ) : reportType === 'suppliers' ? (
            <SuppliersReport
              startDate={startDate}
              endDate={endDate}
              reportData={reportData}
              user={user}
            />
          ) : null
        ).toBlob();

        const url = URL.createObjectURL(blob);
        window.open(url); // Open the PDF in a new tab
        setGenerate(false);
      }
    };

    generatePDF();
  }, [reportType, startDate, endDate, reportData, user]);

  return (
    <div className='p-4 bg-white shadow-md rounded-lg mt-5'>
      <h2 className='flex items-center justify-center mb-5 text-2xl text-primary font-light'>
        Generate A Report
      </h2>
      <form onSubmit={handleSubmit}>
        {/* Dropdown for report type selection */}
        <div className='w-full mb-4'>
          <select
            id='dropdown'
            name='category'
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
            className='h-14 w-full border border-gray-300 px-3 text-base focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent rounded-lg'
          >
            <option value='' disabled>
              Choose A Report Type
            </option>
            <option value='inventoryitems'>Inventory Items</option>
            <option value='orders'>Orders</option>
            <option value='sales'>Sales</option>
            <option value='suppliers'>Suppliers</option>
          </select>
        </div>

        {/* Date pickers */}
        <div className='flex flex-col md:flex-row gap-4 mb-4'>
          <div className='flex-1'>
            <label className='block text-gray-700 mb-1'>Start Date:</label>
            <input
              type='date'
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              required
              className='border border-gray-300 rounded-lg p-2 w-full h-14'
            />
          </div>
          <div className='flex-1'>
            <label className='block text-gray-700 mb-1'>End Date:</label>
            <input
              type='date'
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              required
              className='border border-gray-300 rounded-lg p-2 w-full h-14'
            />
          </div>
        </div>

        {/* Submit button */}
        <button
          type='submit'
          className={`px-3 py-2 w-full md:w-fit border-2 border-primary bg-primary/10 text-primary text-lg hover:bg-primary hover:text-secondary rounded-lg ${
            startDate === '' ? 'cursor-not-allowed' : 'cursor-pointer'
          }`}
          disabled={startDate === ''}
        >
          Generate Report
        </button>
      </form>

      {/* Loading state */}
      {loading && <p>Loading Data...</p>}

      {/* Error message */}
      {error && <p className='text-red-500'>{error}</p>}
      <ToastContainer />
    </div>
  );
};

export default Reports;
