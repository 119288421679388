import React, { useContext, useEffect, useState } from 'react';
import SearchField from '../../components/SearchField';
import Cart from '../../components/Cart';
import Button from '../../components/Button';
import { paymentModes } from '../../constants/paymentModes';
import { InventoryContext } from '../../context/InventoryProvider';
import { AuthContext } from '../../context/AuthProvider';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import colors from '../../assets/colors';
import { useNavigate } from 'react-router-dom';
import api, { setupInterceptors } from '../../api/api';

const POS = () => {
  const { processingSale, setProcessingSale } = useContext(InventoryContext);
  const { user } = useContext(AuthContext);

  const [inventoryList, setInventoryList] = useState([]);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [amountTendered, setAmountTendered] = useState(0);
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);
  const [paymentMode, setPaymentMode] = useState('Cash');
  const [cashier, setCashier] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchOpen, setSearchOpen] = useState(false);

  const [receipt, setReceipt] = useState(null);
  // const [receiptAvailable, setReceiptAvailable] = useState(false);
  const [summary, setSummary] = useState({});

  const token = localStorage.getItem('token');

  const navigate = useNavigate();

  useEffect(() => {
    // Call setupInterceptors and pass the navigate function
    setupInterceptors(navigate);
  }, [navigate]);

  // Fetch the inventory from the db
  useEffect(() => {
    const fetchInventoryList = async () => {
      try {
        const response = await api.get(
          `${process.env.REACT_APP_API_URL}/inventory`,
          {
            headers: {
              token: `Bearer ${token}`,
            },
          }
        );
        const data = response.data;

        setInventoryList(data);
        setItems(data);
        setFilteredItems(data);
      } catch (err) {
        console.error('Error fetching inventory:', err);
      } finally {
        // setLoading(false);
      }
    };
    fetchInventoryList();
  }, [processingSale]);

  // Effect to set the person making the sale
  useEffect(() => {
    setCashier(`${user?.firstName} ${user?.lastName}`);
  }, []);

  // Function to filter items based on search input
  useEffect(() => {
    const lowerCaseValue = searchValue.toLowerCase();

    // Filter items based on search input
    const filtered =
      items &&
      items.filter((item) => {
        const matchesName =
          item?.name && item?.name.toLowerCase().includes(lowerCaseValue);
        const matchesGenericName =
          item?.genericName &&
          item?.genericName.toLowerCase().includes(lowerCaseValue);

        return matchesName || matchesGenericName;
      });

    setFilteredItems(filtered); // Update filtered items
  }, [searchValue, items]);

  // Calculate total when the component mounts or when the cart changes
  useEffect(() => {
    calculateTotal(cart);
  }, [cart]);

  // Function to calculate total
  const calculateTotal = (cartItems) => {
    const newTotal = cartItems.reduce(
      (acc, item) => acc + item?.unitPrice * item?.quantity,
      0
    );
    setTotal(newTotal);
  };

  //Function to add item to the cart
  const addItem = (item) => {
    const existingItem = cart.find((cartItem) => cartItem?._id === item?._id);
    if (existingItem) {
      // If item already exists in cart, increase quantity only (to prevent repeatition of items)
      setCart(
        cart.map((cartItem) =>
          cartItem?._id === item?._id
            ? { ...cartItem, quantity: cartItem?.quantity + 1 }
            : cartItem
        )
      );
    } else {
      // If item does not exist, add it to the cart
      setCart([...cart, { ...item, quantity: 1 }]);
    }
  };

  //Function to remove an item from the cart
  const removeItem = (id) => {
    setCart(cart.filter((item) => item._id !== id));
  };

  //Function to clear the cart
  const clearCart = () => {
    setCart([]);
  };

  //Function to process sale
  const processSale = async () => {
    setProcessingSale(true);
    try {
      // Check if the amount tendered is sufficient
      if (amountTendered < total) {
        toast.error(
          'Amount tendered is less than the total. Please enter a valid amount.',
          {
            position: 'top-center',
            icon: false,
            // Remove the progress bar
            hideProgressBar: true,
            // Styling the pop-up message
            style: {
              backgroundColor: `${colors.error}`,
              color: '#fff',
              textAlign: 'center',
            },
          }
        );
        setProcessingSale(false);
        return;
      }

      // Validate cart items and prepare sale details
      const saleDetails = cart.map((item) => {
        const { name, quantity, unitPrice } = item;

        // Ensure quantity and unitPrice are valid numbers
        const parsedQuantity = Number(quantity);
        const parsedUnitPrice = Number(unitPrice);

        // Validate price and quantity
        if (
          isNaN(parsedUnitPrice) ||
          isNaN(parsedQuantity) ||
          parsedUnitPrice <= 0 ||
          parsedQuantity <= 0
        ) {
          toast.error(
            `Invalid price (${unitPrice}) or quantity (${quantity}) for item: ${name}`,
            {
              position: 'top-center',
              icon: false,
              // Remove the progress bar
              hideProgressBar: true,
              // Styling the pop-up message
              style: {
                backgroundColor: `${colors.error}`,
                color: '#fff',
                textAlign: 'center',
              },
            }
          );
          console.error(
            `Invalid price (${unitPrice}) or quantity (${quantity}) for item: ${name}`
          );
          setProcessingSale(false);
          throw new Error(`Invalid price or quantity for item: ${name}`);
        }

        // Calculate subtotal (unitPrice * quantity)
        const subtotal = parsedUnitPrice * parsedQuantity;

        return {
          name,
          quantity: parsedQuantity,
          unitPrice: parsedUnitPrice,
          subtotal,
        };
      });

      // Prepare sale summary
      const saleSummary = {
        cartItems: saleDetails,
        totalAmount: total, // Total for the entire cart
        amountPaid: amountTendered, // The amount paid by the customer
        paymentMode: paymentMode, // Assume paymentMode is defined elsewhere
        saleBy: cashier,
      };

      // Show a "Saving..." toast notification while data is being processed
      const savingToastId = toast.info(`Processing Sale ...`, {
        position: 'top-center',
        autoClose: false, // Keep it open until dismissed manually
        style: {
          backgroundColor: '#FFD700', // Customize the background color (yellowish for "saving")
          color: '#000',
          textAlign: 'center',
        },
        icon: false,
        hideProgressBar: true,
      });

      // Send sale summary to the backend
      await axios.post(`${process.env.REACT_APP_API_URL}/sales`, saleSummary, {
        headers: {
          token: `Bearer ${token}`,
        },
      });

      // Update stock for each item sold
      for (const item of saleDetails) {
        const { name, quantity } = item;

        // Find the item in the cart to get the current stock
        const itemToUpdate = cart.find((cartItem) => cartItem.name === name);
        if (itemToUpdate) {
          // Calculate new stock value (the stock that is left in inventory)
          const newStock = itemToUpdate.stock - quantity;

          // Update the item in the backend
          await axios.put(
            `${process.env.REACT_APP_API_URL}/items/${itemToUpdate._id}`,
            { stock: newStock },
            {
              headers: {
                token: `Bearer ${token}`,
              },
            }
          );
        }
      }

      // Dismiss the "Saving..." toast
      toast.dismiss(savingToastId);

      toast.success(`Sale processed successfully!`, {
        position: 'top-center',
        autoClose: 500,
        // Styling the pop-up message
        style: {
          backgroundColor: `${colors.success}`,
          color: '#fff',
          textAlign: 'center',
        },
        icon: false,
        // Remove the progress bar
        hideProgressBar: true,
      });

      // createReceipt(saleSummary);
      // setReceiptAvailable(true);
      setSummary(saleSummary);

      clearCart(); // Clear the cart
      setAmountTendered(0); // Reset amount tendered
      setPaymentMode('Cash'); // Reset payment mode to default ('Cash')
      setSearchValue(''); // Reset search field
      setProcessingSale(false);
    } catch (error) {
      toast.error(`Error processing sale: ${error.message} `, {
        position: 'top-center',
        icon: false,
        // Remove the progress bar
        hideProgressBar: true,
        // Styling the pop-up message
        style: {
          backgroundColor: `${colors.error}`,
          color: '#fff',
          textAlign: 'center',
        },
      });
      console.error('Error processing sale:', error.message);
      setProcessingSale(false);
    }
  };

  // Function to print receipt
  const createReceipt = async (saleSummary) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/print/create`,
        saleSummary,
        {
          headers: {
            token: `Bearer ${token}`,
          },
        }
      );

      setReceipt(response.data);
    } catch (error) {
      console.error('Error creating receipt', error);
    }
  };

  // Function to print receipt
  const printReceipt = async (summary) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/print/print`,
        // `${process.env.REACT_APP_NGROKURL}/print/print`,
        summary,
        {
          headers: {
            token: `Bearer ${token}`,
          },
        }
      );

      // setReceiptAvailable(false);
      setSummary({});
      setReceipt(null);
    } catch (error) {
      console.error('Error printing receipt', error);
    }
  };

  //Function to handle closing search bar
  const handleCloseSearch = () => {
    setSearchValue('');
    setSearchOpen(false);
  };

  return (
    <div className='mt-5'>
      <div
        className='flex flex-col lg:flex-row gap-3 overflow-y-auto rounded-lg'
        style={{ height: 'calc(100vh - 140px)' }}
      >
        <div className='flex flex-col flex-[2] gap-3 h-fit'>
          <div className='relative'>
            <div className=' p-2 bg-white rounded-lg shadow-lg'>
              <div className='flex items-center gap-5'>
                <div className='flex-[3]'>
                  <SearchField
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    setSearchOpen={setSearchOpen}
                    handleCloseSearch={handleCloseSearch}
                    placeholder='Scan or Search Item ...'
                  />
                </div>
                <div>
                  <Button
                    label='Clear Cart'
                    onClick={clearCart}
                    className='px-3 py-1 border-2 cursor-pointer border-error bg-error/10 text-error hover:bg-error hover:text-secondary rounded-lg'
                    disabled={cart.length === 0}
                  />
                </div>
              </div>
            </div>
            {searchOpen && searchValue && (
              <div className='absolute p-2 z-10 mt-2 max-h-[200px] w-full bg-white overflow-y-auto rounded-lg shadow-lg'>
                {filteredItems && filteredItems.length > 0 ? (
                  <div>
                    {filteredItems.map((item, index) => (
                      <div
                        key={index}
                        className={`group flex items-center justify-between p-1 hover:bg-gray-200 cursor-pointer rounded-md ${
                          (index + 1) % 2 === 1 ? 'bg-gray-100' : 'bg-white'
                        }`}
                        onClick={() => {
                          addItem(item);
                          setSearchValue('');
                          setSearchOpen(false);
                        }}
                      >
                        <div className='flex items-center justify-between flex-grow'>
                          <div className='flex-1'>
                            <p>{item?.name}</p>
                            <p className='text-[12px]'>{item?.genericName}</p>
                          </div>
                          <p className='flex-1'>{item?.category}</p>
                        </div>
                        <div className=''>
                          <Button
                            label='Add to Cart'
                            className='px-2 py-1 border-2 border-gray-200 group-hover:bg-accent group-hover:text-secondary rounded-md'
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className='flex flex-col items-center justify-center h-40 '>
                    <p>
                      <span className='capitalize font-bold italic'>
                        " {searchValue} "
                      </span>{' '}
                      does not exist in our inventory
                    </p>
                    <p>Try another term</p>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className='w-full'>
            <div
              className='flex flex-wrap justify-between gap-5 overflow-y-auto rounded-lg  capitalize z-10'
              style={{
                maxHeight: 'calc(100vh - 200px)',
              }}
            >
              <Cart
                cart={cart}
                setCart={setCart}
                removeItem={removeItem}
                calculateTotal={calculateTotal}
              />
            </div>
          </div>
        </div>

        <div className='flex-1 bg-white rounded-lg shadow-lg h-fit p-5'>
          <div className=' mb-3 font-urbanist font-light text-3xl w-fit'>
            <p>Checkout</p>
            <div className='h-[2px] bg-black/50 border w-[80%] rounded-full'></div>
          </div>
          <div className='flex justify-between mb-4'>
            <p className='text-xl'>Total: </p>
            <p className='text-xl'>
              {new Intl.NumberFormat('en-US').format(total)} UGX
            </p>
          </div>
          <hr />
          <div className='flex justify-between my-4'>
            <p className='text-xl'>Amount Tendered: </p>
            <div className='flex items-center justify-center gap-2'>
              <input
                type='text'
                className='bg-transparent w-20 text-xl text-right border-b border-white hover:border-gray-600'
                value={amountTendered}
                onChange={(e) =>
                  setAmountTendered(parseInt(e.target.value) || 0)
                }
              />{' '}
              <p className='text-xl'>UGX</p>
            </div>
          </div>
          <hr />
          <div className='flex justify-between my-4'>
            <p className='text-xl'>Change Due: </p>
            <p
              className={`text-xl font-bold ${
                total && amountTendered >= total
                  ? 'text-success'
                  : !total
                  ? 'text-black'
                  : 'text-error'
              }`}
            >
              {new Intl.NumberFormat('en-US').format(
                amountTendered && amountTendered - total
              )}{' '}
              UGX
            </p>
          </div>
          <hr />
          <div className='my-4 bg-slate-100 p-5 rounded-lg'>
            <p className='mb-2'>Add Mode of Payment</p>
            <div className='flex flex-col gap-2'>
              {paymentModes.map((mode, index) => (
                <div key={index} className='flex justify-between items-center'>
                  <Button
                    label={
                      <div className='flex items-center justify-center gap-2'>
                        {mode?.icon} {mode?.mode}
                      </div>
                    }
                    onClick={() => setPaymentMode(mode.mode)}
                    className={`px-2 py-1 border-2 border-gray-300/50 hover:bg-gray-200 text-black rounded-md ${
                      paymentMode === mode?.mode ? 'bg-gray-300' : 'border'
                    }`}
                  />
                  {/* <p>{mode}</p> */}
                  <p>
                    {paymentMode === mode.mode
                      ? new Intl.NumberFormat('en-US').format(total)
                      : 0}{' '}
                    UGX
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div className='mt-5'>
            <Button
              label={`${
                processingSale ? 'Processing Sale ...' : 'Process Sale'
              }`}
              onClick={processSale}
              className={`px-3 py-2 w-full border-2 border-success bg-success/10 text-success text-lg hover:bg-success hover:text-secondary rounded-lg ${
                cart.length === 0
                  ? 'cursor-not-allowed'
                  : processingSale
                  ? 'cursor-wait'
                  : 'cursor-pointer'
              }`}
              disabled={cart.length === 0}
            />
          </div>
        </div>
      </div>
      {/* <div className='z-20'>
        {receiptAvailable && (
          <div className='fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75'>
            <div
              className='flex flex-col gap-2 bg-white p-5 overflow-auto w-[90%] md:w-[30%] rounded-lg'
              style={{ maxHeight: 'calc(100vh - 40px)' }}
            >
              <h3>{receipt?.header}</h3>
              <p>{receipt?.title}</p>
              <p>Date: {receipt?.date}</p>
              <div>
                <h4>Items</h4>
                {receipt?.items.map((item, index) => (
                  <p key={index}>
                    {item.name} - Qty: {item.quantity} - Price: {item.price} UGX
                  </p>
                ))}
              </div>
              <p>Total: {receipt?.totalAmount} UGX</p>
              <p>Amount Paid: {receipt?.amountPaid} UGX</p>
              <p>Change: {receipt?.change} UGX</p>
              <p>Paid by: {receipt?.paymentMode}</p>
              <p>Served by: {receipt?.saleBy}</p>
              <p>{receipt?.footer}</p>
              <div className='flex flex-col md:flex-row items-center justify-between gap-4'>
                <Button
                  label='Cancel'
                  onClick={() => {
                    setReceiptAvailable(false);
                    setReceipt(null);
                  }}
                  className='px-3 py-2 w-full border-2 border-error bg-error/10 text-error text-lg hover:bg-error hover:text-secondary rounded-lg cursor-pointer'
                />
                <Button
                  label='Print'
                  onClick={() => printReceipt(summary)}
                  className='px-3 py-2 w-full border-2 border-accent bg-accent/10 text-accent text-lg hover:bg-accent hover:text-secondary rounded-lg cursor-pointer'
                />
              </div>
            </div>
          </div>
        )}
      </div> */}
      <ToastContainer />
    </div>
  );
};
export default POS;
