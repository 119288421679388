import React from 'react';
import ListAndStat from './ListAndStat';

const Others = ({
  filteredData,
  itemSelected,
  setItemSelected,
  handleItemSelection,
}) => {
  return (
    <div className='flex flex-col md:flex-row w-full'>
      <ListAndStat
        filteredData={filteredData}
        itemSelected={itemSelected}
        setItemSelected={setItemSelected}
        handleItemSelection={handleItemSelection}
      />
    </div>
  );
};

export default Others;
