import React, { useContext, useEffect, useState } from 'react';
import TabsAndCaps from '../../components/TabsAndCaps';
import PPE from '../../components/PPE';
import LiqAndVails from '../../components/LiqAndVails';
import SupsAndVits from '../../components/SupsAndVits';
import InventoryAnalytics from '../../components/InventoryAnalytics';
import { useNavigate } from 'react-router-dom';
import { InventoryContext } from '../../context/InventoryProvider';
import ItemDetails from '../../components/ItemDetails';
import AddItem from '../../components/AddItem';
import Others from '../../components/Others';
import CreamsAndLotions from '../../components/CreamsAndLotions';
// import axios from 'axios';
import EENDrops from '../../components/EENDrops';
import Syrups from '../../components/Syrups';
import MedEquip from '../../components/MedEquip';
import api, { setupInterceptors } from '../../api/api';

const Inventory = () => {
  const {
    inventorySelected,
    filteredData,
    setFilteredData,
    itemSelected,
    setItemSelected,
    itemDetails,
    itemUpdate,
    addItem,
    refetch,
  } = useContext(InventoryContext);

  const [inventoryList, setInventoryList] = useState([]);

  const token = localStorage.getItem('token');

  const navigate = useNavigate();

  useEffect(() => {
    // Call setupInterceptors and pass the navigate function
    setupInterceptors(navigate);
  }, [navigate]);

  // Fetch the inventory from the db
  useEffect(() => {
    const fetchInventoryList = async () => {
      try {
        const response = await api.get(
          `${process.env.REACT_APP_API_URL}/inventory`,
          {
            headers: {
              token: `Bearer ${token}`,
            },
          }
        );
        const data = response.data;

        setInventoryList(data);
      } catch (err) {
        console.error('Error fetching inventory:', err);
      } finally {
        // setLoading(false);
      }
    };
    fetchInventoryList();
  }, [inventorySelected, addItem, refetch]);

  // Filter through inventoryList to get the specific items based on selected inventory
  useEffect(() => {
    const filterInventory = () => {
      if (inventoryList) {
        const filter = inventoryList.filter(
          (item) => item.category === inventorySelected
        );
        setFilteredData(filter);
      }
    };

    filterInventory();
  }, [inventoryList, inventorySelected]);

  //Object containing all the categories
  const componentMap = {
    'Tablets and Capsules': TabsAndCaps,
    'Liquids and Vials': LiqAndVails,
    'Supplements and Vitamins': SupsAndVits,
    'Personal Protective Equipment (PPE)': PPE,
    'Creams and Lotions': CreamsAndLotions,
    'Ear, Eyes and Nose Drops': EENDrops,
    Syrups: Syrups,
    'Medical Equipment': MedEquip,
    Others: Others,
  };

  //Creating a selected component
  const SelectedComponent = componentMap[inventorySelected];

  //Code to handle inventory change
  const handleItemSelection = (item) => {
    setItemSelected(item);
    // setSideBar(!sideBar);

    navigate(`/pharmacy/inventory/${inventorySelected}/${item.name}`);
  };

  return (
    <div className='mt-5'>
      <div
        className='rounded-lg overflow-y-auto'
        style={{ height: 'calc(100vh - 140px)' }}
      >
        {!inventorySelected ? (
          addItem ? (
            <div>
              <AddItem />
            </div>
          ) : (
            <div>
              <InventoryAnalytics />
            </div>
          )
        ) : !itemDetails && !itemUpdate ? (
          <div className='w-full'>
            <div
              className='flex flex-wrap justify-between gap-5 overflow-y-auto rounded-lg md:rounded-t-sm capitalize'
              // style={{
              //   height: 'calc(100vh - 180px)',
              // }}
            >
              {SelectedComponent && (
                <SelectedComponent
                  filteredData={filteredData}
                  itemSelected={itemSelected}
                  setItemSelected={setItemSelected}
                  handleItemSelection={handleItemSelection}
                />
              )}
            </div>
          </div>
        ) : itemDetails ? (
          <ItemDetails />
        ) : itemUpdate ? (
          <AddItem />
        ) : null}
      </div>
    </div>
  );
};

export default Inventory;
