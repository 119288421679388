import React from 'react';
import InputField from './InputField';
import Button from './Button';

const CreateUserInputForm = ({
  oldUser,
  editUser,
  handleSaveUser,
  handleChange,
  saving,
  setSaving,
}) => {
  return (
    <form className='flex flex-col justify-center md:px-4 gap-4'>
      <div className='flex flex-col md:flex-row items-center justify-center w-full gap-4'>
        <InputField
          label='First Name'
          type='text'
          name='firstName'
          value={oldUser.firstName}
          onChange={handleChange}
        />
        <InputField
          label='Last Name'
          type='text'
          name='lastName'
          value={oldUser.lastName}
          onChange={handleChange}
        />
      </div>
      <InputField
        label='Username'
        type='text'
        name='username'
        value={oldUser.username}
        onChange={handleChange}
      />
      <InputField
        label='Email'
        type='text'
        name='email'
        value={oldUser.email}
        onChange={handleChange}
      />
      {!editUser && (
        <InputField
          label='Password'
          type='text'
          name='password'
          value={oldUser.password}
          onChange={handleChange}
        />
      )}
      <div className=' h-14 flex items-center gap-2'>
        <label
          htmlFor='isAdmin'
          className='px-3 text-gray-500 transition-all duration-150 ease-out pointer-events-none'
        >
          Admin
        </label>
        <input
          id='isAdmin'
          type='checkbox'
          name='isAdmin'
          checked={oldUser.isAdmin}
          onChange={handleChange}
          className='h-4 w-4 text-base focus:border-transparent'
        />
      </div>
      <div className=' h-14 flex items-center gap-2'>
        <label
          htmlFor='isCashier'
          className='px-3 text-gray-500 transition-all duration-150 ease-out pointer-events-none'
        >
          Pharmacist
        </label>
        <input
          id='isCashier'
          type='checkbox'
          name='isCashier'
          checked={oldUser.isCashier}
          onChange={handleChange}
          className='h-4 w-4 text-base focus:border-transparent'
        />
      </div>
      {editUser ? (
        <Button
          label={saving ? 'Updating Details ... ' : 'Update Details'}
          onClick={handleSaveUser}
          className={`${
            saving ? 'cursor-wait' : 'cursor-pointer'
          } border p-2 mt-2 rounded-lg bg-green-600 hover:bg-green-800 text-white w-full`}
        />
      ) : (
        <Button
          label={saving ? 'Creating User .... ' : 'Create User'}
          onClick={handleSaveUser}
          className={`${
            saving ? 'cursor-wait' : 'cursor-pointer'
          } border p-2 mt-2 rounded-lg bg-green-600 hover:bg-green-800 text-white w-full`}
        />
      )}
    </form>
  );
};

export default CreateUserInputForm;
