import React, { useContext, useEffect, useRef } from 'react';
import InventoryList from './InventoryList';
import SearchField from './SearchField';
import Button from './Button';
import { IoClose } from 'react-icons/io5';
import { InventoryContext } from '../context/InventoryProvider';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ListAndStat = () => {
  const {
    filteredData, // Assuming this is your initial data
    itemSelected,
    setItemSelected,
    handleItemSelection,
    searchValue,
    setSearchValue,
    inventorySelected,
    setItemDetails,
    setItemUpdate,
    searchResult,
    setRefetch,
    refetch,
    setSearchResult,
  } = useContext(InventoryContext);

  const navigate = useNavigate();
  const detailsRef = useRef(null);

  const token = localStorage.getItem('token');

  // UseEffect to set the list of items to filteredData
  useEffect(() => {
    const data = filteredData;
    setSearchResult(data);
  }, []);

  // Function to filter cases based on search input
  useEffect(() => {
    const lowerCaseValue = searchValue.toLowerCase();

    //Create an array with filtered data
    const filtered = filteredData.filter((item) => {
      const matchesName =
        item?.name && item?.name.toLowerCase().includes(lowerCaseValue);
      const matchesGenericName =
        item?.genericName &&
        item?.genericName.toLowerCase().includes(lowerCaseValue);
      const matchesCountry =
        item?.countryOfOrigin &&
        item?.countryOfOrigin.toLowerCase().includes(lowerCaseValue);
      const matchesManufacturer =
        item?.manufacturer &&
        item?.manufacturer.toLowerCase().includes(lowerCaseValue);
      return (
        matchesName ||
        matchesGenericName ||
        matchesCountry ||
        matchesManufacturer
      );
    });
    setSearchResult(filtered);
  }, [searchValue, filteredData]);

  // Function to handle closing search bar
  const handleCloseSearch = () => {
    setSearchValue(''); // Clear search input
  };

  // Function to view details on an item
  const handleShowDetails = (item) => {
    setItemDetails(true);
    navigate(`/pharmacy/inventory/${inventorySelected}/${item}`);
  };

  // Function to update details on an item
  const handleUpdateDetails = (item) => {
    setItemUpdate(true);
    navigate(`/pharmacy/inventory/${inventorySelected}/${item}`);
  };

  // Function to delete an item
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/items/${id}`, {
        headers: {
          token: `Bearer ${token}`,
        },
      });
      setRefetch(!refetch);
      setItemSelected('');
    } catch (err) {
      console.error('Error deleting user:', err);
      // setError('An error occurred while deleting user.');
    } finally {
      // setLoading(false);
    }
  };

  // Function to handle clicks outside the details section
  const handleClickOutside = (event) => {
    if (detailsRef.current && !detailsRef.current.contains(event.target)) {
      setItemSelected('');
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the detail section
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={detailsRef}
      className='flex flex-col lg:flex-row gap-3 w-full h-full'
    >
      <div
        className='flex-[3] flex flex-col gap-3'
        style={{ height: 'calc(100vh - 140px)' }}
      >
        {(!itemSelected || window.innerWidth >= 1024) && (
          <>
            <div className='bg-white p-2 rounded-lg shadow-lg'>
              <SearchField
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                handleCloseSearch={handleCloseSearch}
                placeholder={`Scan or Search ${inventorySelected} ...`}
              />
            </div>
            {/* Pass the filtered items to the InventoryList */}
            <InventoryList
              searchResult={searchResult}
              handleItemSelection={handleItemSelection}
              category={inventorySelected}
            />
          </>
        )}
      </div>
      {itemSelected && (
        <div className='flex-1 flex flex-col items-center justify-center text-sm p-2 gap-2 bg-accent rounded-lg shadow-lg h-fit sticky top-0'>
          <div className='flex flex-col p-2 bg-white w-full rounded-md'>
            <div className='flex items-center justify-between'>
              <div className='w-fit'>
                <div className='max-w-[200px] text-primary truncate font-urbanist text-xl'>
                  {itemSelected.name}
                </div>
                <div className='h-[2px] bg-black/50 border w-[80%] rounded-full'></div>
              </div>
              <div className='flex items-center justify-end'>
                <div
                  className='cursor-pointer text-red-600 hover:bg-red-600 hover:text-white p-[2px] rounded-md border border-red-600 text-xl'
                  onClick={() => {
                    setItemSelected('');
                  }}
                >
                  <IoClose />
                </div>
              </div>
            </div>
            <div className='flex flex-col gap-2 mt-2'>
              <div className='flex'>
                <p className='flex-1'>Quantity</p>
                <p className='flex-1 font-semibold'>{itemSelected.stock}</p>
              </div>
              <div className='flex'>
                <p className='flex-1'>Side Effects</p>
                <p className='flex-1 font-semibold'>
                  {itemSelected.sideEffects}
                </p>
              </div>
              <div className='flex'>
                <p className='flex-1'>Description</p>
                <p className='flex-1 font-semibold'>
                  {itemSelected.description}
                </p>
              </div>
            </div>
            <div className='h-[2px] bg-black/50 border w-full my-2 rounded-full'></div>
            <div className='flex flex-col gap-1'>
              <div className='flex items-center justify-center gap-1'>
                <div
                  onClick={() => handleDelete(itemSelected._id)}
                  className='flex-1'
                >
                  <Button
                    label='Delete Item'
                    className='px-2 py-1 w-full border-2 border-error bg-error/10 text-error hover:bg-error hover:text-white rounded-md'
                  />
                </div>
                <div className='flex-1'>
                  <Button
                    label='Update'
                    className='px-2 py-1 w-full border-2 border-success bg-success/10 text-success hover:bg-success hover:text-white rounded-md'
                    onClick={() => handleUpdateDetails(itemSelected.name)}
                  />
                </div>
              </div>
              <div className='flex-1'>
                <Button
                  label='View Details'
                  className='px-2 py-1 w-full border-2 border-accent bg-accent/10 text-accent hover:bg-accent hover:text-white rounded-md'
                  onClick={() => handleShowDetails(itemSelected.name)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListAndStat;
