import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OrdersAnalytics from '../../components/OrdersAnalytics';
import PurchaseOrders from '../../components/PurchaseOrders';
import AddSupplier from '../../components/AddSupplier';
import OrdersList from '../../components/OrdersList';
import { OrderContext } from '../../context/OrderProvider';
import Suppliers from '../../components/Suppliers';
// import axios from 'axios';
import api, { setupInterceptors } from '../../api/api';

const Orders = () => {
  const { pageSelected, setPageSelected, orderStatusChange, refetch } =
    useContext(OrderContext);

  const [ordersList, setOrdersList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);

  const token = localStorage.getItem('token');

  const navigate = useNavigate();

  useEffect(() => {
    // Call setupInterceptors and pass the navigate function
    setupInterceptors(navigate);
  }, [navigate]);

  // Fetch the orders from the db
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await api.get(
          `${process.env.REACT_APP_API_URL}/purchase-orders`,
          {
            headers: {
              token: `Bearer ${token}`,
            },
          }
        );
        const data = response.data;

        setOrdersList(data);
      } catch (err) {
        console.error('Error fetching orders:', err);
      } finally {
        // setLoading(false);
      }
    };
    fetchOrders();
  }, [orderStatusChange, pageSelected]);

  // Fetch the suppliers from the db
  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const response = await api.get(
          `${process.env.REACT_APP_API_URL}/suppliers`,
          {
            headers: {
              token: `Bearer ${token}`,
            },
          }
        );
        const data = response.data;

        setSupplierList(data);
      } catch (err) {
        console.error('Error fetching suppliers:', err);
      } finally {
        // setLoading(false);
      }
    };
    fetchSuppliers();
  }, [refetch]);

  // Code to handle order choice
  const handlePageSelection = (item) => {
    setPageSelected(item);
    navigate(`/pharmacy/orders/${item}`);
  };

  return (
    <div className='mt-5'>
      <div
        className='flex flex-col gap-3 rounded-lg overflow-y-auto'
        style={{ height: 'calc(100vh - 140px)' }}
      >
        {!pageSelected ? (
          <OrdersAnalytics
            handlePageSelection={handlePageSelection}
            ordersList={ordersList}
            supplierList={supplierList}
          />
        ) : (
          <>
            {pageSelected === 'generate_P.O' && (
              <PurchaseOrders supplierList={supplierList} />
            )}
            {pageSelected === 'add_supplier' && <AddSupplier />}
            {pageSelected === 'orders' && (
              <OrdersList ordersList={ordersList} />
            )}
            {pageSelected === 'suppliers' && (
              <Suppliers supplierList={supplierList} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Orders;
