import React, { useState } from 'react';

const InputField = ({ label, type, name, value, onChange, min, date }) => {
  const [isFocused, setIsFocused] = useState(!date ? false : true);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div className='relative w-full'>
      <input
        type={type}
        id={name}
        name={name}
        autoComplete='off'
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        min={min || ''}
        className='h-14 w-full border border-gray-300 px-3 pt-5 pb-2 text-base focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent rounded-lg'
        placeholder=' '
      />
      <label
        className={`absolute left-3 transition-all duration-150 ease-out pointer-events-none ${
          isFocused || value
            ? 'top-2 text-xs text-primary'
            : 'top-7 -translate-y-1/2 text-base text-gray-500'
        }`}
        htmlFor={name}
      >
        {label}
      </label>
    </div>
  );
};

export default InputField;
