import React from 'react';
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';

// Create styles for the PDF
const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 10,
    fontFamily: 'Helvetica',
    lineHeight: 1.5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  section: {
    marginBottom: 10,
  },
  purchaseOrder: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  companyInfo: { textAlign: 'left' },
  companyNameLogo: { display: 'flex', flexDirection: 'row', gap: 10 },
  orderInfoHead: { fontWeight: 'bold', fontSize: 18 },
  orderInfo: {
    textAlign: 'left',
  },
  companyName: {
    width: '50%',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    fontSize: 18,
  },
  podetails: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  po: { flex: 1 },
  header: {
    fontSize: 18,
    marginBottom: 20,
    textAlign: 'center',
  },
  facilityDetails: {
    marginBottom: 20,
    flex: 1,
    border: '2px solid black',
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 5,
    borderBottom: '1px solid #ccc',
  },
  tableHeaderRow: {
    fontWeight: 'bold',
    fontSize: 14,
    backgroundColor: '#f2f2f2',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  tableColHeader: {
    paddingTop: 5,
  },
  tableColName: {
    flex: 3,
    textAlign: 'left',
  },
  tableCol: {
    flex: 1,
    textAlign: 'left',
  },
  totalAmount: {
    marginTop: 10,
    fontSize: 14,
    fontWeight: 'bold',
  },
  addresses: {
    borderTop: '1px solid #000',
    borderBottom: '1px solid #000',
    paddingVertical: 5,
    marginVertical: 5,
  },
  vendorShipTo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 20,
    borderRadius: 20,
    backgroundColor: '#f1f1f1',
  },
  vendor: {
    flex: 1,
    textAlign: 'left',
  },
  shipTo: {
    textAlign: 'left',
    flex: 1,
  },
  heading: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  approvedBySection: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 20,
  },
  approvedByName: {
    display: 'flex',
    flexDirection: 'column',
    textTransform: 'capitalize',
  },
  approvedBySignatureAndDate: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 40,
    rowGap: 40,
  },
  approvedBySignature: {
    flex: 2,
    borderTop: '1px solid #000',
    paddingTop: 5,
  },
  approvedDate: {
    flex: 1,
    borderTop: '1px solid #000',
    paddingTop: 5,
  },
});

const PurchaseOrderPDF = ({
  supplierData,
  totalAmount,
  items,
  facilityDetails,
  createdBy,
  amg_logo,
}) => {
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.section}>
          <View style={styles.purchaseOrder}>
            <View style={styles.companyInfo}>
              <View style={styles.companyNameLogo}>
                <Image
                  src={amg_logo}
                  style={{
                    width: 50,
                    height: 50,
                  }}
                />
                <Text style={styles.companyName}>{facilityDetails.name}</Text>
              </View>
              <Text style={styles.companyAddress}>
                {facilityDetails.address}
              </Text>
              <Text>
                {facilityDetails.city}, {facilityDetails.country}
              </Text>
              <Text style={styles.companyPhone}>{facilityDetails.contact}</Text>
            </View>

            <View style={styles.orderInfo}>
              <Text style={styles.orderInfoHead}>Purchase Order</Text>
              <Text>Date: {new Date().toLocaleDateString()}</Text>
              <Text>P.O. Number: 12345678</Text>
              <Text>Customer ID: #123456</Text>
            </View>
          </View>

          <View style={styles.addresses}>
            <View style={styles.vendorShipTo}>
              {supplierData.map((sup, index) => (
                <View key={index} style={styles.vendor}>
                  <Text style={styles.heading}>Order To:</Text>
                  <Text>{sup.name}</Text>
                  <Text>{sup.address}</Text>
                  <Text>
                    {sup.city}, {sup.country}
                  </Text>
                  <Text>{sup.phone}</Text>
                  <Text>{sup.email}</Text>
                </View>
              ))}

              <View style={styles.shipTo}>
                <Text style={styles.heading}>Order By:</Text>
                <Text>{facilityDetails.name}</Text>
                <Text>{facilityDetails.address}</Text>
                <Text>
                  {facilityDetails.city}, {facilityDetails.country}
                </Text>
                <Text>{facilityDetails.contact}</Text>
                <Text>{facilityDetails.email}</Text>
              </View>
            </View>
          </View>

          <Text
            style={{
              marginTop: 10,
              marginBottom: 5,
              fontWeight: 'bold',
              fontSize: 16,
            }}
          >
            Items to be Supplied:
          </Text>
          {/* Table Header */}
          <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableHeaderRow]}>
              <Text style={[styles.tableColName, styles.tableColHeader]}>
                Item
              </Text>
              <Text style={[styles.tableCol, styles.tableColHeader]}>
                Quantity
              </Text>
              <Text style={[styles.tableCol, styles.tableColHeader]}>
                Unit Price
              </Text>
              <Text style={[styles.tableCol, styles.tableColHeader]}>
                Total (UGX)
              </Text>
            </View>

            {/* Table Rows */}
            {items.map((item, index) => (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.tableColName}>{item.name}</Text>
                <Text style={styles.tableCol}>{item.quantity}</Text>
                <Text style={styles.tableCol}>
                  {new Intl.NumberFormat('en-US').format(item.price)}
                </Text>
                <Text style={styles.tableCol}>
                  {new Intl.NumberFormat('en-US').format(item.total)}
                </Text>
              </View>
            ))}
          </View>
          <Text style={styles.totalAmount}>
            Total Amount: {new Intl.NumberFormat('en-US').format(totalAmount)}{' '}
            UGX
          </Text>
        </View>

        {/* Signature and person creating the order  */}
        <View style={styles.approvedBySection}>
          <View style={styles.approvedByName}>
            <Text>Approved By: {createdBy}</Text>
          </View>
          <View style={styles.approvedBySignatureAndDate}>
            <Text style={styles.approvedBySignature}>Authorized Signature</Text>
            <Text style={styles.approvedDate}>Date</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PurchaseOrderPDF;
