import React from 'react';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { MdOutlinePending, MdCancel } from 'react-icons/md';

const getStatusIcon = (status) => {
  switch (status) {
    case 'delivered':
      return <IoCheckmarkCircleOutline />;
    case 'pending':
      return <MdOutlinePending />;
    case 'cancelled':
      return <MdCancel />;
    default:
      return null; // Return null or a default icon if needed
  }
};

const getStatusColor = (status) => {
  switch (status) {
    case 'delivered':
      return 'bg-success/80';
    case 'pending':
      return 'bg-warning/80';
    case 'cancelled':
      return 'bg-error/80';
    default:
      return null; // Return null or a default icon if needed
  }
};

const ListOfOrders = ({ handleOrderSelection, searchResult }) => {
  return (
    <div
      className={`h-fit p-2 rounded-lg overflow-y-auto ${
        searchResult.length > 0 ? 'bg-white shadow-lg' : ''
      }`}
    >
      {searchResult.length > 0 ? (
        <table className='table-auto w-full p-2'>
          <thead className='sticky top-0 w-full text-white'>
            <tr className='text-left'>
              <th className='px-2 py-3 text-center rounded-l-lg bg-primary'>
                No.
              </th>
              <th className='px-2 py-3 bg-primary'>P.O. No.</th>
              <th className='px-2 py-3 bg-primary'>Supplier</th>
              <th className='px-2 py-3 bg-primary truncate max-w-[80px]'>
                Items
              </th>
              <th className='px-2 py-3 text-center bg-primary truncate max-w-[80px]'>
                Amount (UGX)
              </th>
              <th className='px-2 py-3 text-center bg-primary truncate max-w-[80px]'>
                Order Date
              </th>
              <th className='px-2 py-3 text-center bg-primary rounded-r-lg truncate max-w-[80px]'>
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {searchResult
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((order, index) => (
                <tr
                  key={order.id || index}
                  onClick={() => handleOrderSelection(order)}
                  className={`hover:bg-gray-200 cursor-pointer ${
                    (index + 1) % 2 === 0 ? 'bg-gray-100' : 'bg-white'
                  }`}
                >
                  <td className='p-2 rounded-l-lg text-center'>{index + 1}</td>
                  <td className='p-2'>{order.id}</td>
                  <td className='p-2'>{order.supplier}</td>
                  <td className='p-2'>
                    {Array.isArray(order.items) ? (
                      order.items.map((item, itemIndex) => (
                        <p key={item.id || itemIndex}>{item.name}</p>
                      ))
                    ) : (
                      <p>No items</p>
                    )}
                  </td>
                  <td className='p-2 text-right'>
                    {new Intl.NumberFormat('en-US').format(order.totalAmount)}
                  </td>
                  <td className='p-2 text-center'>
                    {new Date(order.createdAt).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </td>
                  <td className='p-2 text-center rounded-r-lg'>
                    <p
                      className={`flex items-center justify-center text-white gap-2 rounded-lg py-2 ${getStatusColor(
                        order.status
                      )}`}
                    >
                      {getStatusIcon(order.status)}
                      {order.status}
                    </p>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <div className='flex items-center justify-center h-40 text-2xl'>
          <p className='capitalize'>No Orders available</p>
        </div>
      )}
    </div>
  );
};

export default ListOfOrders;
