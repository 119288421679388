import React from 'react';
import { IoClose } from 'react-icons/io5';
import { IoIosSearch } from 'react-icons/io';

const SearchField = ({
  searchValue,
  setSearchValue,
  setSearchOpen,
  handleCloseSearch,
  placeholder,
}) => {
  return (
    <div>
      <div className='flex items-center border rounded-lg  bg-white'>
        <div className='ml-2'>
          <IoIosSearch className='text-[#9CA3AF]' />
        </div>
        <input
          className='w-full px-2 py-1 focus:outline-none focus:border-none bg-white '
          type='text'
          name='search'
          id='search'
          placeholder={placeholder}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
            setSearchOpen && setSearchOpen(true);
          }}
        />
        <div
          className='px-2 py-2 text-md bg-gray-300 text-black hover:bg-gray-200 rounded-r-lg cursor-pointer'
          onClick={handleCloseSearch}
        >
          <IoClose />
        </div>
      </div>
    </div>
  );
};

export default SearchField;
