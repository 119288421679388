export const inventory = [
  'Tablets and Capsules',
  'Liquids and Vials',
  'Supplements and Vitamins',
  'Personal Protective Equipment (PPE)',
  'Creams and Lotions',
  'Ear, Eyes and Nose Drops',
  'Syrups',
  'Medical Equipment',
  'Others',
];
